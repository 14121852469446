@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Inter&display=swap");

/** 
* 
* -----------------------------------------------------------------------------
*
* Template :  Autopump - NFT Minting/Collection Landing Page HTML5 Template 

* Author : uigigs
* Author URI : http://www.uigigs.com/
*
* -----------------------------------------------------------------------------
* 
**/
/* TABLE OF CONTENT
---------------------------------------------------------
01. Autopump General CSS
02. Autopump Breadcrumbs CSS
03. Autopump Header Section CSS
04. Mobile Menu Section CSS
05. Mint Section CSS
06. Counter Section CSS
07. About Section CSS
08. Roadmap Section CSS
09. Team Section CSS
10. FAQ Section CSS
11. Latest-details-content Section CSS
12. Blog detail-conent Section CSS
13. Newsletter-item Section CSS
14. Autopump Footer Section CSS
15. Modal Section CSS
16. Preloader CSS

--------------------------------------------------------*/
/* -----------------------------------/
01. Autopump General CSS
-------------------------------------*/
body {
  scroll-padding-top: 80px;
}
html {
  scroll-padding-top: 80px;
}

html,
body {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8) !important;
  font-family: "Inter", "Bakbak One";
  vertical-align: baseline;
  line-height: 1.6;
  font-weight: 500;
  overflow-x: hidden;
  background-color: #040c12 !important;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0px;
  line-height: 1.8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  line-height: 1.35;
  font-family: "Bakbak One";
}

.border_t0 {
  border-top: 0px !important;
}
.border_b0 {
  border-top: 0px !important;
}
.border_l0 {
  border-top: 0px !important;
}
.border_r0 {
  border-top: 0px !important;
}

.uppercase {
  text-transform: uppercase !important;
}

a {
  text-decoration: none !important;
  outline: none;
}

ul,
ol {
  list-style: outside none none;
  margin: 0px;
  padding: 0px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  text-decoration: none !important;
}

button {
  border: none;
  padding: 0px;
}
body .dblock {
  display: block;
}
::-moz-selection {
  background: #2d65f8;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #2d65f8;
  text-shadow: none;
  color: #ffffff;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.hov_shape_show {
  position: relative;
  overflow: hidden;
}
.hov_shape_show .hov_shape1 {
  display: block;
  position: absolute;
  top: 7px;
  left: 7px;
  line-height: 0px;
}

.hov_shape_show .hov_shape2 {
  display: none;
  position: absolute;
  top: 7px;
  right: 7px;
  transform: rotate(90deg);
}
.footer-sec1 .hov_shape_show .hov_shape2 {
  top: 14px;
}
.hov_shape_show:hover .hov_shape2 {
  display: block;
  animation: btn_hov_shape 0.5s;
}
@keyframes btn_hov_shape {
  0% {
    right: -20px;
  }

  100% {
    right: 7px;
  }
}

.btn-small-gray {
  height: 50px;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.btn-small-gray .btn_icon {
  margin-right: 10px;
}
.btn-mid-green {
  height: 60px;
  width: 200px;
  background: #00ffa3;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}
.btn-mid-green .btn_icon {
  margin-right: 10px;
}
.btn-mid-blue {
  height: 60px;
  width: 200px;
  background: #5865f2;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.btn-mid-blue .btn_icon {
  margin-right: 10px;
}
.btn-mid-transparent {
  height: 60px;
  width: 200px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.btn-mid-transparent .btn_icon {
  margin-right: 10px;
}

.autopump_mint_header_right .connect_btn {
  height: 50px;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.autopump_mint_header_right .connect_btn .btn_icon {
  margin-right: 10px;
}

.hov_shape_show .square_hov_shape {
  height: 100%;
  width: 0;
  background: rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0px;
  left: 0px;
  animation: btn_square_shape 0.8s;
  transition: 0.4s;
}

.hov_shape_show .square_hov_shape_dark {
  height: 100%;
  width: 0;
  background: rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0px;
  left: 0px;
  animation: btn_square_shape 0.8s;
}
.hov_shape_show:hover .square_hov_shape,
.hov_shape_show:hover .square_hov_shape_dark {
  width: 100%;
}

@keyframes btn_square_shape {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.height-auto {
  min-height: auto !important;
}
.white-color {
  color: #fff !important;
}
.hov_shape_team {
  position: relative;
}
.hov_shape_team .hov_shape1 {
  opacity: 0;
  transition: 0.4s;
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 0px;
}
.hov_shape_team .hov_shape2 {
  opacity: 0;
  transition: 0.4s;
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(90deg);
  line-height: 0px;
}
.hov_shape_team:hover .hov_shape1,
.hov_shape_team:hover .hov_shape2 {
  opacity: 1;
}
.social_hov_shape_show {
  position: relative;
}
.social_hov_shape_show .social_hov_shape1 {
  display: none;
  position: absolute;
  top: 7px;
  left: 7px;
  line-height: 0px;
}
.social_hov_shape_show:hover .social_hov_shape1 {
  display: block;
}
.autopump_title_section {
  position: relative;
  z-index: 1;
}
.autopump_title_section h2 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #00ffa3;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.autopump_title_section h2 img {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -1px;
}
.autopump_title_section h2 span.shape_left {
  margin-right: 9px;
}
.autopump_title_section h2 span {
  margin-left: 9px;
}
.autopump_title_section h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 67px;
  text-transform: uppercase;
  color: #ffffff;
}
.modal-open .modal::-webkit-scrollbar {
  display: none;
}
body .modal-backdrop {
  opacity: 1 !important;
  background: rgba(4, 12, 18, 0.9);
  backdrop-filter: blur(10px);
}

/* -----------------------
02. Autopump Breadcrumbs CSS
--------------------------*/
.autopump-inner-header {
  position: static;
}
.autopump-breadcrumbs-section {
  background: url(../images/bg/breadcumb-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
  margin-top: 90px;
}
.autopump-breadcrumbs-section .breadcrumbs-link {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  margin-right: 15px;
  position: relative;
  font-family: "Bakbak One";
  font-size: 18px;
}
.autopump-breadcrumbs-section .breadcrumbs-link:before {
  content: "";
  width: 3px;
  height: 3px;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 4px;
  right: -12px;
  transition: 0.4s;
}
.autopump-breadcrumbs-section .breadcrumbs-area .sub-inner span {
  font-family: "Bakbak One";
  color: #00ffa3;
  font-size: 18px;
  margin-right: 10px;
  text-transform: uppercase;
}
.autopump-breadcrumbs-section .breadcrumbs-area .title {
  color: #fff;
  transition: 0.4s;
  cursor: auto;
  text-transform: uppercase;
}

.autopump-breadcrumbs-section .breadcrumbs-link:hover {
  color: #00ffa3;
}
.autopump-breadcrumbs-section .breadcrumbs-form {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0;
}
.autopump-breadcrumbs-section .breadcrumbs-form form {
  position: relative;
  z-index: 1;
}
.autopump-breadcrumbs-section .breadcrumbs-form form i {
  position: absolute;
  top: 14px;
  right: 35px;
}
.autopump-breadcrumbs-section .breadcrumbs-form .breadcrumbs-social a {
  color: #b8b8bd;
  text-transform: uppercase;
}
.autopump-breadcrumbs-section .breadcrumbs-form .breadcrumbs-social a i {
  font-size: 15px;
}
.autopump-breadcrumbs-section .breadcrumbs-form input {
  background: transparent;
}
.autopump-breadcrumbs-section .breadcrumbs-form input[type="text"] {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 11px 20px;
  width: 380px;
  color: rgba(255, 255, 255, 0.7);
}
.autopump-breadcrumbs-section .breadcrumbs-form input[type="submit"] {
  font-size: 0;
  border: none;
}
.breadcrumbs-form .gamfi-navigation {
  position: relative;
  top: -3px;
}

.breadcrumbs-form .gamfi-navigation ul {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.breadcrumbs-form .gamfi-navigation ul li:first-child {
  top: 0;
}
.breadcrumbs-form .gamfi-navigation ul li:first-child a {
  padding-top: 11px;
  padding-bottom: 8px;
}
.breadcrumbs-form .gamfi-navigation {
  position: relative;
  top: -3px;
}
.breadcrumbs-form .gamfi-navigation ul {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.breadcrumbs-form .gamfi-navigation ul li:first-child {
  top: 0;
}
.breadcrumbs-form .gamfi-navigation ul li:first-child a {
  padding-top: 11px;
  padding-bottom: 8px;
}
.gamfi-navigation ul li {
  display: inline-block;
  position: relative;
}
.gamfi-navigation ul li + li {
  margin-left: 4px;
}
.gamfi-navigation ul li:first-child {
  top: 2px;
}
.gamfi-navigation ul li:first-child a {
  padding: 11px 19px 8px;
  position: relative;
  top: -1px;
}
.gamfi-navigation ul li a {
  display: block;
  border: 1px solid #2e2f3c;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: 0.4s;
}
.gamfi-navigation ul li a .icon-Vector {
  font-size: 13px;
}

.gamfi-navigation ul li a .icon-arrow_right {
  font-size: 13px;
}

.gamfi-navigation ul li a.active:not(.black-shape),
.gamfi-navigation ul li a:hover:not(.black-shape) {
  background: #2e2f3c;
  color: #00ffa3;
}

/* -----------------------------------/
03. Autopump Header Section CSS
-------------------------------------*/
.autopump_header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  margin-top: 1px;
  height: 90px;
  z-index: 99999;
}
.header_social_links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 124px;
  width: 100%;
  margin-right: 10px;
}
.header_social_links li img {
  width: 20px;
  margin-bottom: 8px;
}
.header_social_links li a i {
  font-size: 20px;
  color: #ffffff;
}
.autopump_header.sticky {
  position: fixed;
  top: -3px;
  left: 0;
  transform: translateY(-100%);
  transition: 0.6s;
  animation: slideIn 0.6s ease forwards;
  box-shadow: 0px 4px 6px rgba(62, 0, 0, 0.1);
  background: rgba(62, 88, 94, 0.1);
  backdrop-filter: blur(15px);
  z-index: 1000;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.mobile_menu_social_links {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 124px;
  width: 100%;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 0px;
}
.mobile_menu_social_links li a i {
  font-size: 20px;
  color: #ffffff !important ;
}
.mobile_menu_social_links li a {
  color: white !important;
}
.autopump_menu_sect {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autopump_menu_left_sect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
}
.autopump_menu_list {
  width: 100%;
}
.autopump_menu_list ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0px !important;
  gap: 20px;
}
.autopump_menu_list ul li {
  cursor: pointer;
}
.autopump_menu_list ul li.submenu .sub-menu-sect {
  background: transparent;
  border-top: 50px solid transparent;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 100;
  width: 100%;
  z-index: 11111;
  display: none;
}
.autopump_menu_list ul li.submenu .sub-menu-sect .autopump-megha-menu {
  background: #051117;
}
.autopump_menu_list ul li.submenu .sub-menu-sect .mint_menu_left {
  padding-top: 0px;
  padding-left: 0px;
}
.autopump_menu_list ul li.submenu .sub-menu-sect .mint_menu_right {
  padding-top: 30px;
}
.autopump-mega-menu-section .mint_home_menu {
  margin-top: 30px;
}
.autopump-mega-menu-section .mint_right_menu_list1_content ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.autopump-mega-menu-section .mint_right_menu_list1_content ul li a {
  text-transform: capitalize;
}
.autopump_menu_list ul li.submenu:hover .sub-menu-sect {
  animation-name: menuShow;
  animation-duration: 0.2s;
}
.autopump-mega-menu-section .mint_menu_overlay {
  position: absolute;
}
@keyframes menuShow {
  from {
    top: 0px;
    opacity: 0;
  }
  to {
    top: 40px;
    opacity: 1;
  }
}

.autopump_menu_list ul li.submenu ol {
  padding: 15px 20px;
  background: #171f25;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.autopump_menu_list ul li.submenu:hover .sub-menu-sect {
  display: block;
}
.autopump_menu_list ul li ol li a {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
}
.autopump_menu_list ul li ol li {
  width: 49%;
}
.autopump_menu_list ul li ol li a:hover {
  color: #ffffff;
}
.autopump_menu_list ul li a {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.3s;
  position: relative;
}

.autopump_menu_list ul li a::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #00ffa3;
  transition: all 0.3s;
}

.autopump_menu_list ul li:hover a::before {
  width: 100%;
}

.autopump_menu_list ul li:hover a {
  color: #00ffa3;
}

.autopump_menu_right_sect {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autopump_menu_right_sect.autopump_v2_menu_right_sect {
  justify-content: flex-end;
}
.autopump_menu_right_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autopump_menu_right_sect button.join_btn {
  height: 50px;
  width: 114px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  color: #fff;
}
.autopump_menu_right_sect button.join_btn img {
  margin-right: 10px;
}
.autopump_menu_right_sect button.join_btn {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.autopump_menu_right_sect button.join_btn a img {
  margin-right: 10px;
}
.autopump_menu_right_sect button.join_btn a span {
  margin-right: 14px;
}
.autopump_menu_right_sect button.connect_btn {
  height: 50px;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  margin-left: 20px;
  box-sizing: border-box;
  width: 100%;
}
.autopump_menu_right_sect button.connect_btn {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.autopump_menu_right_sect button.connect_btn img {
  margin-right: 10px;
}
.autopump_menu_right_sect button.connect_btn span img {
  margin-right: 0px;
}
.autopump_menu_right_sect button span img {
  margin: 0px;
  padding: 0px;
}
.autopump_menu_right_sect button.connect_btn a span {
  margin-right: 14px;
}
.menu_bar {
  display: none;
  border: none;
  outline: none;
  background: none;
  padding: none;
  text-align: right;
}
.menu_bar i {
  font-size: 30px;
  color: #ffffff;
  opacity: 80%;
}
.menu_bar:hover i {
  opacity: 100%;
}

/*-------------------------------------------
04. Mobile Menu Section CSS
-------------------------------------------*/
.autopump_mobile_menu {
  background: rgba(4, 12, 18, 0.9);
  backdrop-filter: blur(10px);
  height: 100vh;
  width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
  opacity: 1;
  display: none;
}
.autopump_mobile_menu_content {
  background: #000000;
  height: 100vh;
  width: 400px;
  padding: 50px;
  margin-left: auto;
  overflow-y: scroll;
}
.autopump_mobile_menu_content::-webkit-scrollbar {
  display: none;
}

/*
.nav-expanded .autopump_mobile_menu{
    right: 0px;
    opacity: 1;
    display: block;
}
*/
.mobile_menu_logo {
  width: 100%;
  position: relative;
}
.mobile_menu_logo .mobile_menu_close_btn {
  position: absolute;
  right: 0px;
  top: -10px;
  background: none;
  border: none;
  outline: none;
}
.mobile_menu_logo .mobile_menu_close_btn i {
  color: #ffffff;
  font-size: 30px;
}

.autopump_mobile_menu_list {
  margin-top: 50px;
}
.autopump_mobile_menu_list ul {
}
.autopump_mobile_menu_list ul li {
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.autopump_mobile_menu_list ul li a {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
}
.autopump_mobile_menu_list .mobile-sub-menu-list {
  display: none;
  position: absolute;
  left: 0px;
  top: 40px;
  width: 100%;
  background: #000000;
  z-index: 2;
}
.autopump_mobile_menu_list .mobile-sub-menu-list li {
  padding: 0px 25px;
}
.autopump_mobile_menu_list .mobile-sub-menu-list li a {
  text-transform: capitalize;
}
.mobile_menu_btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.mobile_menu_btns button.join_btn {
  height: 50px;
  width: 100%;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  margin-top: 20px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.mobile_menu_btns button.join_btn img {
  margin-right: 14px;
}
.mobile_menu_btns button.connect_btn {
  height: 50px;
  min-width: 155px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  box-sizing: border-box;
  margin-top: 20px;
}
.mobile_menu_btns button.connect_btn {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.mobile_menu_btns button.connect_btn img {
  margin-right: 14px;
}
.mobile_menu_btns button.connect_btn span img {
  margin: 0px;
}

/*-------------------------------------------
04. Banner Section CSS
-------------------------------------------*/
.autopump_v1_baner_sect {
  background: url(../images/bg/h1_banner_bg.png);
  min-height: 950px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 192px;
}
.autopump_v2_baner_sect {
  /* background: url(../images/nft/v2_banner_bG.png); */
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 192px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.autopump_v3_baner_sect {
  background: url(../images/bg/banner_bg_3.jpg);
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 165px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.v2_baner_stars {
  top: -80px;
  height: 499px;
}
.autopump_v2_baner_content {
  margin-top: 135px;
  position: relative;
}
.autopump_v2_baner_content h2 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 29px;
  position: relative;
  z-index: 2;
}
.autopump_v2_baner_content h2 span {
  color: #ff004c;
}
.autopump_v2_baner_content h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  z-index: 2;
}
.autopump_v2_baner_content .autopump_v2_timer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.autopump_v2_baner_content .autopump_v2_timer h4 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.autopump_v2_baner_content .autopump_v2_timer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
.autopump_v2_baner_content .autopump_v2_timer ul li {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-right: 25px;
  margin-right: 10px;
}

.autopump_v2_baner_content .autopump_v2_timer ul li {
  position: relative;
}
.autopump_v2_baner_content .autopump_v2_timer ul li:before {
  position: absolute;
  right: 0;
  top: -4px;
  content: ":";
  color: rgba(255, 255, 255, 0.2);
}
.autopump_v2_baner_content .autopump_v2_timer ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.autopump_v2_baner_content .autopump_v2_timer ul li:last-child:before {
  display: none;
}

.autopump_v2_baner_content .autopump_v2_timer ul li span {
  display: none;
}
.autopump_v2_baner_content .autopump_v2_timer ul strong {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.2);
  padding: 0px 10px;
}
.autopump_v1_baner_left {
  margin-top: 66px;
  max-width: 500px;
  width: 100%;
  min-height: 550px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.autopump_v1_baner_left h2 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 80px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 32px;
}
.autopump_v1_baner_left h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;
  margin-bottom: 58px;
  color: #ffffff;
}

.autopump_v1_baner_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.autopump_v1_baner_buttons button.widhlist_btn {
  height: 60px;
  width: 200px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  margin-left: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.autopump_v1_baner_left ul {
  margin-top: 37px;
}
.autopump_v1_baner_left ul li {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}
.autopump_v1_baner_left ul li span {
  color: #00ff48;
}
.autopump_v1_baner_right {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-top: 66px;
}
.autopump_v1_baner_right_img_sect {
  position: relative;
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-conent3 .banner-subtitle {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}
.banner-conent3 .banner-subtitle span {
  color: #ff004c;
}
.banner-conent3 .banner-title {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;
  color: #ffffff;
  margin-bottom: 45px;
}
.banner-conent3 .banner-title span {
  color: #ff004c;
}
.banner-conent3 h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-transform: uppercase;
  color: #ffffff;
}
.banner-conent3 .autopump_v3_timer .timer_1 {
  display: block;
  margin-bottom: 40px;
}
.banner-conent3 .autopump_v3_timer .timer_1 ul li {
  position: relative;
  padding-right: 25px;
  margin-right: 10px;
}
.banner-conent3 .autopump_v3_timer .timer_1 ul li:before {
  position: absolute;
  right: 0;
  top: -4px;
  content: ":";
  color: rgba(255, 255, 255, 0.2);
}
.banner-conent3 .autopump_v3_timer .timer_1 ul li:last-child:before {
  display: none;
}
.banner-conent3 .autopump_v3_timer h5 {
  font-size: 18px;
  color: #fff;
}
.banner-conent3 .banner-bottom-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-family: "Bakbak One";
  max-width: 240px;
  margin-top: 52px;
  line-height: 1.5;
}
.banner-conent3 .autopump_v3_timer h4 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-transform: uppercase;
  color: #ffffff;
}
.banner-conent3 .autopump_v3_timer ul {
  display: flex;
  align-items: center;
  padding-left: 0px !important;
}
.banner-conent3 .autopump_v3_timer ul li {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-transform: uppercase;
  color: #ffffff;
}
.banner-conent3 .autopump_v3_timer ul li span {
  margin-left: 5px;
}
.banner-conent3 .autopump_v3_timer ul strong {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.2);
  padding: 0px 10px;
}
.autopump_v3_baner_sect .banner-image-area3 {
  padding: 110px 0;
}
.autopump_v3_baner_sect .banner-image1,
.autopump_v3_baner_sect .banner-image-area3 {
  position: relative;
  z-index: 3;
}
.autopump_v3_baner_sect .banner-image1 {
  z-index: 3;
  top: -25px;
}
.banner-count-inner .banner-btn-area {
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.autopump_v3_baner_sect .banner-image2,
.autopump_v3_baner_sect .banner-image3 {
  position: absolute;
  bottom: 40px;
}
.autopump_v3_baner_sect .banner-image2 {
  left: -52px;
}
.autopump_v3_baner_sect .banner-image3 {
  right: 75px;
  z-index: 4;
  bottom: 130px;
}
.banner-image-area3 .shape_1 {
  position: absolute;
  top: 15%;
  width: 72px;
}
.banner-image-area3 .shape_2 {
  position: absolute;
  top: 22%;
  left: 68%;
  width: 40px;
}
.banner-image-area3 .shape_3 {
  position: absolute;
  top: 76%;
  left: 40%;
  width: 64px;
}
.autopump_v1_baner_right_img_bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.autopump_v1_baner_right_img_bg img {
  width: 100%;
  height: 100%;
}
.autopump_v1_baner_right_img {
  position: relative;
  z-index: 1;
}

/*-------------------------------------------
05. Mint Section CSS
-------------------------------------------*/
.how_to_mint_sect {
  margin-top: 113px;
  padding-bottom: 85px;
}
.how_to_mint_sect .how_to_mint_container {
  position: relative;
}
.how_to_mint_shadow {
  position: absolute;
  top: -370px;
  left: -130px;
  max-width: 687px;
  width: 100%;
  height: 547px;
  z-index: 0;
}
.how_to_mint_content {
  position: relative;
  z-index: 1;
}
.how_to_mint_content ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.how_to_mint_content ul li {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.how_to_mint_content ul li h4 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 140px;
  text-transform: uppercase;
  color: transparent;
}
.how_to_mint_content ul li p {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  max-width: 148px;
  width: 100%;
  margin-left: 35px;
}
.how_to_mint_content ul li h4.green {
  -webkit-text-stroke: 1px rgba(0, 255, 163, 0.7);
}
.how_to_mint_content ul li h4.blue {
  -webkit-text-stroke: 1px rgba(88, 101, 242, 0.7);
}
.how_to_mint_content ul li h4.yellow {
  -webkit-text-stroke: 1px rgba(255, 230, 0, 0.7);
}
.how_to_mint_content ul li h4.red {
  -webkit-text-stroke: 1px rgba(255, 0, 76, 0.7);
}
.mint_live_circle_sect {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  border: none;
  background: #ffe600;
  position: absolute;
  top: -65px;
  left: -65px;
  z-index: 1;
}
.mint_live_circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.mint_live_text {
  animation: rotated-style 20s linear infinite;
  position: absolute;
}
.autopump-mint-section {
  padding-bottom: 100px;
  margin-top: 0;
}
.mint_count_list {
  margin: 30px 0px;
}
.mint_count_list ul {
  padding-left: 0px;
}

.mint_count_list ul li h5 {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.mint_count_list ul li h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
}

.mint_count_list ul li .mint_quantity_sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 106px;
  width: 100%;
  height: 100%;
}
.mint_count_list ul li .mint_quantity_sect input {
  max-width: 100px;
  width: 100%;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.mint_count_list ul li .mint_quantity_sect button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.autopump_v1_baner_buttons button.mint_btn {
  height: 60px;
  width: 200px;
  background: #00ffa3;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}
.modal_mint_btn {
  height: 60px;
  width: 100%;
  background: #00ffa3;
  border: none;
  margin-bottom: 12px;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}
.autopump_v2_baner_buttons button.mint_btn {
  height: 60px;
  width: 200px;
  background: #00ffa3;
  border: none;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
  margin-top: 41px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
}

.autopump_v3_baner_buttons button.mint_btn.red {
  background-color: red !important;
  color: white !important;
}

.autopump_v3_baner_buttons button.mint_btn {
  height: 60px;
  width: 200px;
  background: #00ffa3;
  border: none;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}

/*-------------------------------------------
06. Counter Section CSS
-------------------------------------------*/
.autopump_total_counter_sect {
  background: rgba(13, 27, 38, 0.7);
  min-height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.autopump_total_counter_sect ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  z-index: 1;
  position: relative;
}
.autopump_total_counter_sect ul li {
  width: 25%;
}
.autopump_total_counter_sect ul li h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 36px;
  text-transform: uppercase;
  color: #ffffff;
}
.autopump_total_counter_sect ul li h4 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.8);
}
.autopump_total_counter_sect .square_shape1 {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
}
.autopump_total_counter_sect .square_shape2 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 0;
}
.autopump_total_counter_sect .square_shape3 {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 0;
}
.autopump_total_counter_sect .square_shape4 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 0;
}

/*--------------------------------
07. About Section CSS
---------------------------------*/
.v1_about_us_section {
  background: url(../images/bg/h1_about_us_bg.png);
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v1_about_us_right_text {
  margin-top: 46px;
}
.v1_about_us_right_text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 20px;
  text-align: center;
}
.v1_about_us_left_sect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 560px;
  width: 100%;
}
.autopump-about-section3 {
  background: url(../images/bg/about-bg3.png);
  background-size: cover;
  background-position: center top -50px;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}
.v1_about_us_img_card1 {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  height: 328px;
  width: 270px;
  padding: 24px;
  position: absolute;
  top: 50px;
  left: 30px;
  transform: rotate(-15deg);
  z-index: 1;
}
.v1_about_us_img1 {
  max-width: 230px;
  height: 230px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
}
.v1_about_us_img1_shapes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.v1_about_us_img1_shapes_left {
}
.v1_about_us_img1_shapes_left .shape_1 {
  background: rgba(255, 255, 255, 0.1);
  height: 15px;
  width: 115px;
}
.v1_about_us_img1_shapes_left .shape_2 {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  height: 8px;
  width: 75px;
}
.v1_about_us_img1_shapes_right .shape_3 {
  background: rgba(255, 255, 255, 0.1);
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.v1_about_us_img_card2 {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  height: 279px;
  width: 230px;
  padding: 20px;
  position: absolute;
  top: 150px;
  right: 20px;
  transform: rotate(15deg);
  z-index: 0;
}
.v1_about_us_img2 {
  max-width: 195px;
  height: 195px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
}
.v1_about_us_img2_shapes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.v1_about_us_img2_shapes_left {
}
.v1_about_us_img2_shapes_left .shape_1 {
  background: rgba(255, 255, 255, 0.1);
  height: 13px;
  width: 98px;
}
.v1_about_us_img2_shapes_left .shape_2 {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  height: 7px;
  width: 64px;
}
.v1_about_us_img2_shapes_right .shape_3 {
  background: rgba(255, 255, 255, 0.1);
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.about_us_text_card_sect {
  margin-top: 120px;
}
.about_us_text_card {
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  min-height: 220px;
  padding: 35px;
  border: 3px solid transparent;
  transition: all 0.8s;
  height: 100%;
}
.about_us_text_card:hover {
  border: 3px solid #00ffa3;
}
.about_us_text_card h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
}
.about_us_text_card h3 img {
  padding-right: 10px;
}
.about_us_text_card h3 span {
  margin-right: 13px;
}
.about_us_text_card p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  max-width: 289px;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 18px;
}

.v1_about_us_section .ab-content .ab-item-box {
  padding: 20px 20px;
  background: #212027;
}
.v1_about_us_section .ab-content .ab-item-box .ab-text a {
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
  transition: 0.4s;
}
.v1_about_us_section .ab-content .ab-item-box .ab-text a img {
  margin-left: 10px;
}
.v1_about_us_section .ab-content .ab-item-box .ab-text a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.v1_about_us_section .ab-content .ab-item-box span {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.v2_about_us_section {
  padding-top: 133px;
  padding-bottom: 0px;
  background: url(../images/nft/v2_about_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 914px;
  width: 100%;
  position: relative;
}
.v2_about_overlay {
  height: 70%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(4, 12, 18, 0) 0%,
    rgba(4, 12, 18, 0.7) 33.33%,
    #040c12 56.77%
  );
  position: absolute;
  bottom: 0px;
  z-index: 1;
}
.v2_about_us_section .about_us_text_card_sect {
  position: relative;
  z-index: 1;
}
.v2_about_us_content {
  position: relative;
}
.v2_about_us_text {
  max-width: 770px;
  width: 100%;
  margin: auto;
  margin-top: 46px;
  position: relative;
  z-index: 2;
}
.v2_about_us_text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
}
.v2_about_us_text ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 414px;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}
.v2_about_us_text ul li h4 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.v2_about_us_text ul li h5 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.v2_about_left_img {
  position: absolute;
  left: 0px;
  top: 10px;
  z-index: 0;
}
.v2_about_right_img {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 0;
}

/*--------------------------------
08. Roadmap Section CSS
---------------------------------*/
.autopump_roadmap_section3 {
  background-repeat: no-repeat;
  padding-bottom: 30px;
  background-position: left top 220px;
  position: relative;
}
.autopump_roadmap_section3:after {
  content: url(../images/bg/roadmap3.png);
  bottom: -350px;
  left: 0px;
  position: absolute;
  z-index: 2;
}
.autopump_roadmap_section3 .container {
  position: relative;
  z-index: 3;
}
.v1_roadmap_sect {
  padding-top: 120px;
  padding-bottom: 140px;
  position: relative;
  z-index: 1;
}
.autopump_roadmap_mass_gard {
  position: absolute;
  right: 0px;
  top: 50%;
  z-index: 0;
}
.v1_roadmap_divider {
  width: 100%;
  margin-top: 53px;
}
.v1_roadmap_divider img {
  width: 100%;
}
.v1_roadmap_contents_inner {
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: grab;
}
.v1_roadmap_contents {
  margin-top: 33px;
}
.v1_roadmap_sect button.slick-prev.slick-arrow,
button.slick-next.slick-arrow {
  display: none !important;
}
.v1_roadmap_sect .slick-track {
  display: flex;
}
.v1_roadmap_contents_text {
  width: 100%;
  padding-right: 30px;
}
.v1_roadmap_contents_text h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #00ffa3;
}
.v1_roadmap_contents_text h3 span {
  margin-left: 10px;
}

.v1_roadmap_contents_text.disable h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}
.v1_roadmap_contents_text h4 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 24px;
}
.v1_roadmap_contents_text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 270px;
}
.autopump_roadmap_section
  .autopump_roadmap_item
  .autopump_roadmap_content
  .autopump_roadmap_counter
  .roadmap_parcentage {
  font-size: 48px;
  font-family: "Bakbak One";
  background: #1d242b;
  color: #343a3e;
  width: 220px;
  margin-right: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  padding: 20px;
}
.autopump_roadmap_section
  .autopump_roadmap_item
  .autopump_roadmap_content
  .autopump_roadmap_counter
  span {
  font-size: 80px;
  line-height: 100%;
  margin-bottom: 12px;
}
.v3_roadmap_content {
  padding-top: 53px;
}
.autopump_roadmap_section .autopump_roadmap_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 1170px;
  width: 100%;
}
.autopump_roadmap_section .autopump_roadmap_content {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 20px 20px;
  margin-right: 20px;
  max-width: 970px;
  width: 100%;
  min-height: 200px;
}
.autopump_roadmap_section
  .autopump_roadmap_item
  .autopump_roadmap_content
  .autopump_roadmap_text {
  position: relative;
  z-index: 9;
}
.autopump_roadmap_section
  .autopump_roadmap_item
  .autopump_roadmap_content
  .autopump_roadmap_text
  .roadmap_heading
  img {
  position: absolute;
  top: 10px;
  right: 0;
}
.autopump_roadmap_section
  .autopump_roadmap_item
  .autopump_roadmap_content
  .autopump_roadmap_text
  a {
  color: #fff;
  font-size: 24px;
  transition: 0.4s;
}
.autopump_roadmap_section
  .autopump_roadmap_item
  .autopump_roadmap_content
  .autopump_roadmap_text
  a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.autopump_roadmap_section .autopump_roadmap_item .roadmap_img {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  min-height: 200px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  width: 100%;
}
.v2_roadmap_sect {
  padding-top: 80px;
  padding-bottom: 150px;
  position: relative;
}
.lottie-image {
  top: -60px;
  right: 0;
  position: absolute;
}
.lottie-feature-image {
  top: -10px;
  left: 0;
  position: absolute;
}
.v2_rodmap_card_sect {
  position: relative;
  z-index: 2;
}
.v2_roadmap_card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 30px 40px;
}
.v2_roadmap_card h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  color: #00ffa3;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v2_roadmap_card h3 span.not-completed {
  -webkit-animation: spin-style 2s linear infinite;
}

.v2_roadmap_card p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
}
.autopump_v2_roadmap_mass_gard {
  position: absolute;
  bottom: -400px;
  text-align: center;
  width: 100%;
}
.v2_rodmap_card_sect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 52px;
}
.v2_roadmap_divider {
  position: absolute;
  top: -35px;
}
.v2_roadmap_divider img {
  height: 95vh;
}

.v2_roadmap_card_sect.v2_roadmap_card_sect_odd {
  padding: 30px 30px 0px 0px;
  margin-bottom: 80px;
  position: relative;
  width: 95%;
  margin-right: auto;
}
.v2_roadmap_card_sect.v2_roadmap_card_sect_even {
  padding: 30px 0px 0px 30px;
  margin-top: 80px;
  position: relative;
  width: 95%;
  margin-left: auto;
}
.v2_roadmap_card_sect.v2_roadmap_card_sect_even.v2_roadmap_card_sect_2 {
  margin-top: 130px;
}
.v2_roadmap_card_sect.v2_roadmap_card_sect_odd .v2_roadmap_card_box {
  background: rgba(255, 255, 255, 0.05);
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.v2_roadmap_card_sect.v2_roadmap_card_sect_even .v2_roadmap_card_box {
  background: rgba(255, 255, 255, 0.05);
  height: 30px;
  width: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
}

/*--------------------------------
09. Team Section CSS
---------------------------------*/
.v1_team_sect {
  background: rgba(25, 35, 40, 0.5);
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;
}
.v1_team_overlay {
  background: url(../images/bg/v1_team_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  padding-top: 113px;
  padding-bottom: 85px;
}
.autopump_team_content {
  margin-top: 53px;
  position: relative;
  z-index: 1;
}
.autopump_team_card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  max-width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 33px;
  border: 3px solid transparent;
  top: 0px;
  transition: all 0.8s;
}

.autopump_team_card:hover {
  border: 3px solid #00ffa3;
  top: -20px;
}

.autopump_team_card h3 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  margin-top: 28px;
  margin-bottom: 4px;
}
.autopump_team_card h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.team_social_icon {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team_social_icon ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 197px;
  width: 100%;
}
.team_social_icon ul li {
  padding: 10px;
  background: #0f181d;
  opacity: 0;
  transition: 0.4s;
  position: relative;
  top: 5px;
  left: -13px;
}
.team_social_icon ul li a {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #ffffff;
}
.autopump_team_card:hover .team_social_icon ul li {
  opacity: 1;
}
.team-section {
  padding-bottom: 90px;
  background: #040c12;
}
.v2_team_sect {
  background: rgba(25, 35, 40, 0.5);
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
  padding-top: 80px;
}
.team_bear_img {
  position: absolute;
  left: 0;
  top: -213px;
}
.team_bear_img img {
  position: relative;
  width: 200px;
}
.v2_team_overlay {
  background: transparent;
}

.v3_team_sect {
  position: relative;
  z-index: 1;
}
.v3_team_sect .v1_team_overlay {
  padding-bottom: 140px;
}
.v3_team_sect .container {
  position: relative;
  z-index: 3 !important;
}
.v3_team_sect .team_social_icon ul li {
  background: #040c12;
}

/*--------------------------------
10. FAQ Section CSS
---------------------------------*/
.autopump_faq_sect {
  padding-bottom: 120px;
  padding-top: 130px;
}
.autopump_faq_content {
  max-width: 770px;
  width: 100%;
  margin: auto;
  position: relative;
}
.faq_bg_wattermark {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.autopump_faq_questions {
  margin-top: 57px;
  position: relative;
}
.autopump_faq_questions .faq_questions {
  position: relative;
  z-index: 1;
}
.autopump_faq_questions .accordion {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  height: 76px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 29px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: none;
  margin-top: 10px;
}
.autopump_faq_questions .accordion.active {
  color: #ffffff;
}
.autopump_faq_questions .accordion:hover {
  color: #ffffff;
}
.autopump_faq_questions .accordion span {
  min-width: 18px;
  margin-left: 20px;
  right: 20px;
}
.autopump_faq_questions .accordion .IconPlus {
  opacity: 0.5;
}
.autopump_faq_questions .accordion:hover .IconPlus {
  opacity: 1;
}
.autopump_faq_questions .accordion.active .IconPlus {
  display: none;
}
.autopump_faq_questions .accordion .IconMunise {
  display: none;
}
.autopump_faq_questions .accordion.active .IconMunise {
  display: block;
}
.autopump_faq_questions .accordion-panel {
  padding: 0px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}
.autopump_faq_questions .accordion-panel p {
  padding: 0px 48px 24px 29px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0px;
  max-width: 100%;
}
.autopump-faq-section {
  padding-bottom: 130px;
  position: relative;
}
.autopump-faq-section .Autopump_faq_text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.autopump-faq-section .Autopump_faq_text ul li {
  color: #fff;
  font-family: "Bakbak One";
}
.autopump-faq-section .Autopump_faq_text ul li span {
  color: #00fca1;
  padding-left: 10px;
}
.autopump_faq_questions.autopump_v2_faq_questions .accordion {
  margin: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.autopump_faq_questions.autopump_v2_faq_questions .accordion.active {
  background: rgba(255, 255, 255, 0.03);
}
.autopump_faq_questions.autopump_v2_faq_questions .accordion-panel {
  background: rgba(255, 255, 255, 0.03);
}
.faq_bear_img {
  position: absolute;
  top: 80px;
  right: -163px;
  z-index: 2;
}

/* ------------------------------------
11. Latest-details-content Section CSS
---------------------------------------*/
.blog-list-content {
  padding-top: 70px;
  padding-bottom: 140px;
  background: transparent;
  position: relative;
  z-index: 0;
}
.blog-list-content .blog-item1 {
  margin-bottom: 30px;
}
.blog-list-content .blog-item1 .blog-text1 {
  background: #11181e;
  padding: 32px 30px;
}
.blog-list-content .blog-item1 .blog-text1 .sec-heading p {
  margin-bottom: 15px;
}
.blog-list-content .blog-item1 .sub-inner {
  margin-bottom: 10px;
  display: block;
  color: #00ffa3;
  text-transform: uppercase;
}
.blog-list-content .blog-item1 .sub-inner span {
  position: relative;
  padding-right: 14px;
  font-family: "Bakbak One";
}
.blog-list-content .blog-item1 .sub-inner a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Bakbak One";
}
.blog-list-content .blog-item1 .blog-btn a {
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  transition: 0.4s;
}
.blog-list-content .blog-item1 h4.title {
  font-size: 24px;
}
.blog-list-content .blog-item1 h4.title a {
  margin-bottom: 15px;
  display: block;
  color: #ffffff;
  transition: 0.4s;
}
.blog-list-content .blog-item1 h4.title a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-list-content .blog-item1 .blog-btn a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  font-family: "Bakbak One";
}
.blog-list-content .blog-item1 .blog-btn a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-list-content .blog-item1 .blog-text1 .blog-link .blog-icon a {
  color: #fff;
}
.blog-list-content .blog-item1 .blog-text1 .blog-link .blog-icon a span {
  color: #fff;
  transition: 0.4s;
}
.blog-list-content .blog-item1 .blog-text1 .blog-link .blog-icon a img {
  margin-right: 10px;
}
.blog-list-content .blog-item1 .blog-text1 .blog-link .blog-icon a img:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-list-content .blog-item1 .blog-text1 .blog-link .blog-icon a span:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-list-content .blog-item1 .blog-text1 .sec-heading {
  position: relative;
  z-index: 9;
}
.blog-list-content .blog-item1 .blog-text1 .sec-heading .blog-dots:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  background: rgba(255, 255, 255, 0.7);
  left: 35px;
  top: 15px;
}
.blog-list-content
  .blog-item1
  .blog-text1
  .sec-heading
  .blog-dots.dots1:before {
  left: 83px;
}
.blog-list-content
  .blog-item1
  .blog-text1
  .sec-heading
  .blog-dots.dots2:before {
  left: 98px;
}
.blog-list-content
  .blog-item1
  .blog-text1
  .sec-heading
  .blog-dots.dots3:before {
  left: 50px;
}
.blog-list-content
  .blog-item1
  .blog-text1
  .sec-heading
  .blog-dots.dots4:before {
  left: 33px;
}
.blog-list-content
  .blog-item1
  .blog-text1
  .sec-heading
  .blog-dots.dots5:before {
  left: 65px;
}

/* -----------------------------------------------
12. Blog detail-conent Section CSS
-------------------------------------------------*/
.blog-detail-conent1 {
  padding-top: 60px;
  padding-bottom: 140px;
}
.blog-detail-conent1 .details-icon ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-detail-conent1 .details-icon ul li {
  margin-bottom: 30px;
  padding-top: 60px;
  display: inline-block;
  color: #fff;
  padding-right: 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Bakbak One";
}
.blog-detail-conent1 h1 {
  margin-bottom: 40px;
}
.blog-detail-conent1 a {
  transition: 0.4s;
  color: #fff;
  margin-bottom: 45px;
}
.blog-detail-conent1 a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-detail-conent1 .details-icon ul li i {
  margin-right: 10px;
}
.blog-detail-conent1 .details-item1 .blog-description .des {
  font-size: 18px;
  line-height: 36px;
  color: #fff;
}
.blog-detail-conent1 .details-item1 .blog-description .des-heading h4 {
  color: #fff;
}
.blog-detail-conent1 .details-item1 .blog-description .blockquote {
  position: relative;
  z-index: 99;
}
.blog-detail-conent1 .details-item1 .blog-description .blockquote:before {
  position: absolute;
  content: "";
  height: 86px;
  width: 20px;
  top: 15px;
}
.blog-detail-conent1 .details-item1 .blog-description .blockquote .des-active {
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  padding-left: 65px;
  color: #fff;
}
.blog-detail-conent1 .details-item1 .igo-blog h4 {
  margin-bottom: 15px;
}
.blog-detail-conent1 .details-item1 .Tokenomics-item .project-service {
  background: #1e1f35;
  margin-right: 75px;
  padding: 45px 0 20px 65px;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-service
  .project-image
  img {
  margin-right: 90px;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-service
  .project-content
  .project-sale {
  position: relative;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-service
  .project-content
  .project-sale:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #fff;
  top: 7px;
  left: -20px;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.darkslateblue:before {
  background: #6d4afe;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.cyan:before {
  background: #3bcbff;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.dodgerBlue:before {
  background: #0b58fe;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.turquoise:before {
  background: #01cc9b;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.springGreen:before {
  background: #a3ff12;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.darkturquoise:before {
  background: #14a0c0;
}
.blog-detail-conent1
  .details-item1
  .Tokenomics-item
  .project-content
  .project-sale.turquoises:before {
  background: #3bcbff;
}
.blog-detail-conent1 .details-item1 .igo-blog ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.blog-detail-conent1 .details-item1 .igo-blog ul li {
  position: relative;
}
.blog-detail-conent1 .details-item1 .igo-blog ul li span {
  line-height: 36px;
}
.blog-detail-conent1 .details-item1 .igo-blog ul li:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #3a3b48;
  line-height: 8px;
  border-radius: 8px;
  top: 15px;
}
.blog-detail-conent1 .details-item1 .igo-blog ul li span {
  padding-left: 25px;
}
.blog-detail-conent1 .details-item1 .about-blog .about-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-menu .breadcrumbs-form .about-breadcrumbs a {
  text-transform: uppercase;
  font-family: "Bakbak One";
}
.about-menu .breadcrumbs-form .about-breadcrumbs i {
  font-size: 15px;
  margin-right: 5px;
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .about-menu
  .about-list
  .about-listing {
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .about-menu
  .about-list
  .about-listing
  li {
  display: inline-block;
  padding-right: 8px;
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .about-menu
  .about-list
  .about-listing
  li
  a {
  background: #1d242a;
  padding: 15px 15px;
  display: inline-block;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 0px;
}
.blog-detail-conent1 .details-item1 .about-blog .about-menu .bolg-share a {
  text-transform: uppercase;
}
.blog-detail-conent1 .details-item1 .about-blog .about-menu .bolg-share i {
  font-size: 15px;
  margin-right: 10px;
}
.blog-detail-conent1 .blog-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blog-detail-conent1 .details-item1 .about-blog .previous-item {
  display: flex;
  align-items: center;
  background: #11181e;
  padding: 10px 25px 10px 10px;
  margin-right: 30px;
}
.blog-detail-conent1 .details-item1 .about-blog .previous-item .previous-text {
  padding-left: 15px;
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .previous-item
  .previous-text
  h5 {
  font-size: 18px;
}
.blog-detail-conent1 .details-item1 .about-blog .previous-item .previous-image {
  margin-right: 10px;
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .previous-item
  .previous-text
  span {
  font-size: 14px;
  text-transform: uppercase;
  color: #00ffa3;
  margin-bottom: 10px;
  display: block;
  font-family: "Bakbak One";
}
.blog-detail-conent1 .details-item1 .about-blog .next-item {
  display: flex;
  align-items: center;
  background: #11181e;
  padding: 10px 10px 10px 35px;
  text-align: right;
}
.blog-detail-conent1 .details-item1 .about-blog .next-item .next-text {
  padding-right: 15px;
}
.blog-detail-conent1 .details-item1 .about-blog .next-item .next-text span {
  font-size: 14px;
  text-transform: uppercase;
  color: #00ffa3;
  margin-bottom: 10px;
  display: block;
  font-family: "Bakbak One";
}
.blog-detail-conent1 .details-item1 .about-blog .next-item .next-text h5 a,
.blog-detail-conent1
  .details-item1
  .about-blog
  .previous-item
  .previous-text
  h5
  a {
  color: #ffffff;
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .next-item
  .next-text
  h5
  a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-detail-conent1
  .details-item1
  .about-blog
  .previous-item
  .previous-text
  h5
  a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.blog-detail-conent1 .details-item1 .comment-item .blog-comment {
  background: #11181e;
  margin-bottom: 20px;
  padding: 30px 30px;
}
.blog-detail-conent1 .details-item1 .comment-item h4 {
  text-transform: uppercase;
  color: #fff;
}
.blog-detail-conent1 h3,
.blog-detail-conent1 h4 {
  color: #fff;
}

.blog-detail-conent1 .details-item1 .comment-item .blog-comment .blog-replay {
  display: flex;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-image
  img {
  min-width: 50px;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-text {
  padding-left: 15px;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-text
  h5 {
  margin-bottom: 0;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-text
  span {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-text
  p {
  margin-bottom: 15px;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-text
  img {
  margin-right: 10px;
}
.blog-detail-conent1
  .details-item1
  .comment-item
  .blog-comment
  .blog-replay
  .replay-text
  .replay-icon
  a {
  text-transform: uppercase;
  font-size: 14px;
}
.blog-detail-conent1 .details-item1 .comment-item .blog-comments {
  padding: 30px 30px;
  margin-left: 100px;
}
.blog-detail-conent1 .details-item1 .form-item .form-heading h4 {
  text-transform: uppercase;
}
.blog-detail-conent1 .details-item1 .form-item .input-button input {
  background: transparent;
  width: 100%;
  border: 1px solid #2e2f3c;
  padding: 15px 20px;
  color: #fff;
}
.blog-detail-conent1 .details-item1 .form-item .input-button input.textarea {
  padding-bottom: 90px;
}
.blog-detail-conent1 .details-item1 .form-item .shape-button {
  margin: 0;
}
.gamfi-navigation ul li {
  display: inline-block;
  position: relative;
}
.blog-detail-conent1 .details-item1 .form-item .input-button {
  margin-bottom: 40px;
  position: relative;
  padding-top: 40px;
}
.blog-detail-conent1 .details-item1 .form-item .input-button label {
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}
.blog-detail-conent1 .details-item1 .form-item .input-submit input {
  width: 100%;
  background: #6d4afe;
  color: #fff;
  border: none;
  padding: 17px;
  text-transform: uppercase;
  transition: 0.4s;
  font-weight: 700;
}
.blog-detail-conent1 .details-item1 .form-item .input-submit {
  margin-bottom: 0;
}
.blog-detail-conent1
  .details-item1
  .form-item
  .input-submit:hover
  .hover-shape1 {
  left: 47%;
}
.blog-detail-conent1
  .details-item1
  .form-item
  .input-submit:hover
  .hover-shape3 {
  left: 51%;
}
.categories-area {
  background: #0e161c;
  padding: 30px 30px 8px;
  margin-bottom: 30px;
}
.categories-item .catergories-form {
  border: 1px solid #3e3f51;
  position: relative;
  padding: 10px 20px;
}
.categories-item .catergories-form input {
  background: transparent;
  border: none;
  color: #fff;
}
.categories-item .catergories-form i {
  top: 15px;
  position: absolute;
  right: 20px;
  font-size: 14px;
  color: #fff;
  transition: 0.4s;
}
.categories-item .catergories-form i:hover {
  color: rgba(255, 255, 255, 0.7);
}
.categories-item .categories-summary ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.categories-item .categories-summary ul li a {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.4s;
  line-height: 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.7);
}
.categories-item .categories-summary ul li a:hover {
  color: #ffffff;
}
.post-item .post-shade h4 {
  text-transform: uppercase;
}
.post-item .post-shade {
  margin-top: 35px;
  display: block;
}
.post-item .post-shade img {
  padding-left: 10px;
}
.post-item .recent-content {
  display: flex;
  margin-bottom: 20px;
}
.post-item .recent-content .recent-text {
  padding-left: 15px;
}
.post-item .recent-content .recent-text span {
  margin-bottom: 10px;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.post-item .recent-content .recent-text h5 a {
  color: #fff;
}
.post-item .recent-content .recent-text h5 a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.tag-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tag-menu ul li {
  display: inline-block;
  padding-right: 5px;
  margin-bottom: 35px;
}
.tag-menu ul li a {
  background: #1d242a;
  padding: 15px 15px;
  font-style: italic;
}
.latest-item.hover-shape-inner .latest-text {
  overflow: hidden;
}
.latest-item.hover-shape-inner .latest-text,
.latest-item.hover-shape-inner {
  position: relative;
}
div.latest-item .hover-shape-bg {
  z-index: 3;
}

/* ------------------------------------
13. Newsletter-item Section CSS
---------------------------------------*/
.newsletter-item.hover-shape-inner {
  background: radial-gradient(circle, #4a4176 -53%, #1e1f35 0%);
  position: relative;
  z-index: 9;
  padding: 30px 30px;
}
.newsletter-item.hover-shape-inner .hover_shape1 {
  left: 22%;
}
.newsletter-item.hover-shape-inner .hover_shape2 {
  left: 60.5%;
  width: 60px;
}
.newsletter-item.hover-shape-inner .hover_shape3 {
  left: 41%;
  width: 40px;
}
.autopump-blog-content {
  padding-bottom: 100px;
  padding-top: 0;
}
.categories-area h4.blog-title {
  font-size: 18px;
  text-transform: uppercase;
}
.categories-area h4.blog-title img {
  margin-bottom: 3px;
}
.newsletter-item h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 240px;
  width: 100%;
  color: #ffffff;
}
.newsletter-item .newsletter-link form input[type="email"] {
  width: 100%;
  border: 2px solid #494a5c;
  background: transparent;
  color: #fff;
  padding: 15px 0 15px 20px;
  margin-bottom: 30px;
}
.newsletter-item .newsletter-link .newsletter-btn input[type="submit"] {
  padding: 17px 0;
  width: 100%;
  text-transform: uppercase;
  background: #a3ff12;
  border: transparent;
}

/*--------------------------------
05. Partner Section CSS
---------------------------------*/
.our_partnar_content {
  margin-top: 57px;
}
.our_partnar_content ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.our_partnar_content ul li {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  height: 150px;
}
.our_partnar_content ul li.bg_none {
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: transparent;
}

/*--------------------------------
05. Owl Carousel CSS
---------------------------------*/
.autopump_v2_owl_carousel_sect {
  background: #08171f;
  padding: 50px 25px;
  position: relative;
  z-index: 1;
}
.autopump_v2_owl_carousel_sect::-webkit-scrollbar {
  display: none;
}
.autopump_v2_owl_carousel_content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.autopump_v2_owl_carousel_sect ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autopump_v2_owl_carousel_sect ul li {
  height: 170px;
  width: 170px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}
.autopump_v2_owl_carousel_sect ul li.bg_none {
  background: transparent;
}
.autopump_owl_carousel_content ul li {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 290px;
  width: 290px;
  margin-right: 20px;
}
.autopump_owl_carousel_sect {
  padding: 20px 40px;
  position: relative;
  z-index: 1;
}
.autopump_owl_carousel_content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.autopump_owl_carousel_content ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autopump_owl_carousel_content ul li {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 290px;
  width: 290px;
  margin-right: 20px;
}
.swiper-autoplay-slider .swiper-slide {
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  padding-top: 55px;
  padding-bottom: 55px;
  cursor: pointer;
}

.home1-avater-slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.home1-avater-slider ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden;
  animation: 18s linear 0s infinite normal none running loop-animate;
  margin-bottom: 20px;
}
.home1-avater-slider ul li .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*

@keyframes loop-animate {
    0% {
        transform: translateX(0px);
    }
    
    100% {
        transform: translateX(-100%);
    }
}
*/
@keyframes loop-animate {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-100%);
  }
}
@keyframes loop-animate-reverse {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0%);
  }
}

/*--------------------------------
14. Autopump Footer Section CSS
---------------------------------*/
.autopump_v1_footer_sect {
  background: url(../images/bg/v1_footer_bg_1.webp);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 70px;
}
.autopump_v1_footer_content {
  position: relative;
}
.join_comunity_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin: auto;
  margin-top: 47px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}

.join_comunity_btns button.wishlist_btn {
  height: 60px;
  width: 200px;
  background: #fff;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}

.join_comunity_btns button.twitterX_btn {
  height: 50px;
  width: 170px;
  background: black;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: white;
  transition: all 0.5s;
}

.join_comunity_btns button.telegram_btn {
  height: 50px;
  width: 170px;
  background: #3390ec;
  border: none;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: white;
  transition: all 0.5s;
}

.join_comunity_btns button.join_discord_btn {
  height: 50px;
  width: 170px;
  background: #5865f2;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.categories-area h4.blog-title {
  color: #fff;
}
.autopump_v2_baner_content span {
  display: inline-block;
}
.join_comunity_btns button.join_discord_btn a {
  color: #ffffff;
}
.join_comunity_btns button.join_discord_btn img {
  margin-right: 9px;
}
.join_comunity_btns button.join_discord_btn span img {
  margin: 0px;
}

.autopump_v1_footer_sect .join_comunity_btns a {
  width: fit-content !important;
}
.autopump_v1_footer_sect .join_comunity_btns {
  justify-content: center;
  gap: 20px;
}

.footer_stras_sect {
  height: 356px;
  width: 100%;
  position: absolute;
  top: -150px;
  z-index: 0;
}
.footer_stars {
  position: relative;
  height: 100%;
  widows: 100%;
}
.footer_stars span {
  position: absolute;
}
.footer_stars .star_1 {
  top: 0%;
  right: 5%;
  animation: star1 4s infinite;
}
@keyframes star1 {
  0%,
  100% {
    top: 0%;
  }

  50% {
    top: 3%;
  }
}
.footer_stars .star_2 {
  top: 20%;
  left: 0%;
  animation: star2 5s infinite;
}
@keyframes star2 {
  0%,
  100% {
    top: 20%;
  }

  50% {
    top: 23%;
  }
}
.footer_stars .star_3 {
  top: 34%;
  left: 20%;
  animation: star3 4s infinite;
}

.footer_stars .star_4 {
  top: 50%;
  left: 44%;
  animation: star4 4s infinite;
}

.footer_stars .star_5 {
  bottom: 26%;
  right: 27%;
  animation: star5 4s infinite;
}

.footer_stars .star_6 {
  bottom: 15%;
  right: 10%;
  animation: star6 3.5s infinite;
}

@keyframes star6 {
  0%,
  100% {
    bottom: 15%;
  }

  50% {
    bottom: 18%;
  }
}
.footer_stars .star_7 {
  bottom: 0px;
  left: 10%;
  animation: star7 4.5s infinite;
}
@keyframes star7 {
  0%,
  100% {
    bottom: 0%;
  }

  50% {
    bottom: 3%;
  }
}
.footer_stars .star_8 {
  top: 10%;
  right: 20%;
}
.footer_stars .star_9 {
  bottom: 15%;
  right: 38%;
  animation: star9 4.5s infinite;
}
@keyframes star9 {
  0%,
  100% {
    bottom: 14%;
  }

  50% {
    bottom: 17%;
  }
}
.footer_stars .star_10 {
  top: 25%;
  left: 35%;
}
.footer_stars .star_11 {
  bottom: 18%;
  left: 20%;
}
.footer_stars .star_12 {
  bottom: 35%;
  left: 30%;
}
.footer_social_links {
  max-width: 540px;
  margin: auto;
  margin-bottom: 70px;
  position: relative;
  z-index: 1;
}
.footer_social_links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0rem !important;
}
.footer_social_links ul li {
  width: 11%;
  height: 60px;
  margin: auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.footer_social_links ul li a {
  color: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_social_links ul li a i {
  color: #ffffff;
  font-size: 20px;
}
.autopump_v1_main_footer {
  position: relative;
  min-height: 180px;
  width: 100%;
}
.autopump_v1_main_footer .autopump_v1_main_footer_overlay {
  background: linear-gradient(
    180deg,
    rgba(4, 15, 21, 0) 0%,
    rgba(4, 14, 20, 0.270833) 50.52%,
    #040c12 100%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.autopump_v1_main_footer .three_charectre_img {
  text-align: center;
}
.footer_bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 100px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}
.footer_bottom_content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_bottom_content .footer_shapes_left {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 0;
}
.footer_bottom_content .footer_shapes_right {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  z-index: 0;
}
.footer_bottom_content .footer_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  position: relative;
  z-index: 1;
}
.bottom_footer_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  text-align: center;
}
.copiright_text {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.8);
  width: fit-content;
  margin-inline: auto;
}

.bottom_footer_left .copiright_text a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  padding-left: 39px;
  color: rgba(255, 255, 255, 0.8);
}
.bact_to_top_btn {
  background: transparent;
  border: none;
  outline: none;
  background: #00ffa3;
  padding: 15px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  right: -42px;
  top: -75px;
  width: 40px;
  color: black;
}

.bottom_footer_right {
  max-width: 394px;
  width: 100%;
}
.bottom_footer_right ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  margin-bottom: 0px !important;
}
.bottom_footer_right ul li a {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.bottom_footer_right ul li a:hover {
  color: #fff;
}
.autopump_v1_footer_sect.footer-sec1 {
  position: relative;
  padding-top: 75px;
  padding-bottom: 40px;
  background: url(../images/bg/cta-left-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-1 {
  position: absolute;
  left: 27%;
  bottom: 35px;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-2 {
  position: absolute;
  top: 40px;
  left: 12%;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-3 {
  position: absolute;
  right: 15%;
  top: 50px;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-4 {
  position: absolute;
  top: 70px;
  right: 47%;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-5 {
  position: absolute;
  top: 70px;
  right: 32%;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-6 {
  position: absolute;
  right: 20%;
  bottom: 55px;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-7 {
  position: absolute;
  right: 12%;
  bottom: 40px;
}
.autopump_v1_footer_sect.footer-sec1 .star-image .star-8 {
  position: absolute;
  right: 43%;
  bottom: 70px;
}
.autopump-footer {
  min-height: auto;
  clear: both;
}
.autopump-footer .footer-main {
  position: static;
}
.autopump-footer .footer-main .footer_menu .copiright_text a {
  padding-left: 0;
}
.autopump-footer .footer-main .footer_menu .bottom_footer_right ul li {
  padding-right: 30px;
}
.autopump-footer
  .footer-main
  .footer_menu
  .bottom_footer_right
  ul
  li:last-child {
  padding-right: 0;
}
.autopump_v1_footer_sect.footer-sec1 .list-btn {
  margin-right: 0;
}
.autopump_v1_footer_sect.footer-sec1 .list-btn .wishlist_btn {
  text-transform: uppercase;
}
.autopump_v1_footer_sect.footer-sec1 .list-btn .wishlist_btn img {
  margin-right: 10px;
}
.autopump_v1_footer_sect.footer-sec1 .list-btn .wishlist_btn a {
  color: #111111;
}
.autopump_v1_footer_sect.footer-sec1 .list-btn .join_discord_btn {
  text-transform: uppercase;
}
.main-footer-content {
  padding-bottom: 90px;
  padding-top: 150px;
  background: url(../images/bg/footer_bg3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  clear: both;
  overflow: hidden;
}
.main-footer-content .footer_image img {
  margin-bottom: 45px;
}
.main-footer-content .menu-title {
  margin-bottom: 45px;
  color: #fff;
}
.main-footer-content .footer-menu .menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.main-footer-content .footer-menu .menu-list li {
  line-height: 45px;
}
.main-footer-content .footer-menu .menu-list li a {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.4s;
}
.main-footer-content .footer-menu .menu-list li a:hover {
  color: #fff;
}
.main-footer-content .footer_newsletter .footer-button {
  position: relative;
  z-index: 2;
}
.main-footer-content .footer_newsletter .footer-button input {
  width: 100%;
  background: transparent;
  border: 2px solid #343d40;
  color: rgba(255, 255, 255, 0.7);
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
}
.main-footer-content .footer_newsletter .footer-button a {
  background: #00ffa3;
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 70px;
  right: 0;
}
.main-footer-content .footer_newsletter .footer-button a img {
  position: relative;
  top: 15px;
  left: 27px;
}
.autopump_v2_footer_sect {
  padding-top: 105px;
  position: relative;
}
.autopump_v2_footer_bg {
  /* background: url(../images/nft/v2_footer_bg.png); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  height: 80%;
  width: 100%;
  bottom: 0px;
}
.autopump_v2_main_footer {
  position: relative;
  width: 100%;
  padding-bottom: 51px;
  margin-top: 494px;
}
.autopump_v2_main_footer .v2_footer_shapes_left {
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.autopump_v2_main_footer .v2_footer_shapes_right {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.autopump_v2_main_footer .v2_main_footer {
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.v2_footer_logo {
  margin-bottom: 46px;
}
.v2_footer_menu {
  max-width: 550px;
  width: 100%;
}
.bottom_footer_menulist ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.autopump_v2_main_footer .v2_main_footer ul li a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.v2_footer_copiright_text {
  margin-bottom: 40px;
}
.v2_footer_copiright_text a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.v2_bact_to_top_btn {
  background: transparent;
  border: none;
  outline: none;
}

/* ------------------------------------
15. Modal Section CSS
---------------------------------------*/

.modal .modal-dialog {
  max-width: 440px;
  margin: auto;
  margin-top: 50px;
}

.modal-content {
  background: #171c21;
  backdrop-filter: blur(5px);
  border-radius: 0px;
  border: none;
}
.modal_overlay {
  height: 100%;
  width: 100%;
  background: url(../images/bg/modal_overlay.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 45px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}
.modal_overlay2 {
  height: 100%;
  width: 100%;
  background: url(../images/bg/modal2_overlay.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 45px;
  padding-bottom: 50px;
  position: relative;
}

.modal_bottom_shape {
}
.modal_bottom_shape .modal_bottom_shape_left {
  position: absolute;
  left: 10px;
  bottom: 10px;
  transform: rotate(-90deg);
}
.modal_bottom_shape .modal_bottom_shape_right {
  position: absolute;
  right: 10px;
  bottom: 10px;
  transform: rotate(180deg);
}

.modal_header button {
  background: transparent;
  border: none;
  outline: none;
  height: 45px;
  width: 45px;
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;
}
.modal_header button:before {
  content: "";
  background: rgba(255, 255, 255, 0.1);
  height: 150%;
  width: 150%;
  position: absolute;
  right: -35px;
  top: -35px;
  transform: rotate(45deg);
}
.modal_header button i {
  position: absolute;
  bottom: 20px;
  right: 8px;
  color: #ffffff;
  font-size: 14px;
}

.modal_header h2 {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 280px;
  margin: auto;
  margin-bottom: 26px;
}
.modal_body {
  padding: 0px;
}

.connect_modal .modal_body p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 24px;
}
.modal_body h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.modal_body h6 a {
  font-weight: 600px;
  color: #ffffff;
}

.connect_modal .modal-content .connect-section .heading-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.connect_modal .modal-content .connect-section .heading-list li {
  display: block;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 15px 30px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  text-align: left;
}
.connect_modal .modal_body .connect-section .heading-list li span {
  width: 35px;
  display: inline-block;
  margin-right: 20px;
}
.connect_modal .modal_body .connect-section .heading-list li:before {
  content: "\e902";
  position: absolute;
  top: 22px;
  right: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "icomoon";
  font-size: 10px;
}
.connect_modal .modal_body .connect-section .heading-list li a {
  color: #fff;
  font-family: "Russo One", sans-serif;
}

.mint_count_list {
  margin: 30px 0px;
}
.mint_count_list ul {
}
.mint_count_list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.mint_count_list ul li h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
}

.mint_count_list ul li .mint_quantity_sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 106px;
  width: 100%;
  height: 100%;
}
.mint_count_list ul li .mint_quantity_sect input {
  max-width: 100px;
  width: 100%;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.mint_count_list ul li .mint_quantity_sect button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.modal_mint_btn {
  height: 60px;
  width: 100%;
  background: #00ffa3;
  border: none;
  margin-bottom: 12px;
  box-sizing: border-box;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #111111;
}

.input-number {
  width: 80px;
  vertical-align: top;
  text-align: center;
  outline: none;
}
.input-number,
.input-number-decrement,
.input-number-increment {
  border: none;
  height: 60px;
  user-select: none;
}

.input-number-decrement,
.input-number-increment {
  display: inline-block;
  width: 60px;
  line-height: 60px;
  background: rgba(255, 255, 255, 0.06);
  color: #fff;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.input-number-decrement:active,
.input-number-increment:active {
  background: rgba(255, 255, 255, 0.08);
}
.input-number {
  background: transparent;
  color: #fff;
  border-right: none;
  border-top: 2px solid rgba(255, 255, 255, 0.06);
  border-bottom: 2px solid rgba(255, 255, 255, 0.06);
}
.input-number-increment {
  border-left: none;
}

.mr-0 {
  margin-right: 0px !important;
}
.modal_header.share_modal_header h2 {
  text-align: left !important;
  margin: 0px !important;
  margin-right: auto !important;
  margin-bottom: 26px !important;
}

.modal_share_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 25px;
}
.modal_share_button li {
  max-width: 60px;
  margin-right: 33px;
  width: 100%;
  height: 60px;
  margin-bottom: 33px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_share_button li a i {
  color: #ffffff;
  font-size: 20px;
}

/*-------------------------------------
16. Preloader CSS
---------------------------------------*/
section.loader_first {
  background: #1c2023;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.loader_first_inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_first .circular-spinner {
  width: 60px;
  height: 60px;
  border: 10px solid rgba(255, 255, 255, 0.1);
  border-top: 10px solid #00ffa3;
  border-radius: 50%;
  animation: pre_rotate 1.5s linear infinite;
  position: absolute;

  transform: translate(-50%, -50%);
}
@keyframes pre_rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
  0% {
    display: block;
  }
  100% {
    display: none;
  }
}
.spine {
  animation: spine 5s linear infinite;
  -webkit-animation: spine 5s linear infinite;
}
@keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.rotated-style {
  -webkit-animation: rotated-style 30s linear infinite;
}
@keyframes rotated-style {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

/*-------------------------
index_v4
-------------------------*/

.autopumpv4_menu_right_buttons {
  justify-content: flex-end;
  min-width: 150px;
}
.autopumpv4_menu_list {
  margin-right: 29px;
}
.autopump_v4_baner_sect {
  background: url(../images/bg/main-banner-bg.webp);
  min-height: 104vh;
  width: 100%;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  padding-top: 120px;
  position: relative;
}

@media (max-width: 991px) {
  .autopump_v4_baner_sect {
    padding-top: 150px;
  }
}
.autopump_v4_baner_left {
  margin-top: 0px;
  min-height: auto;
}
.autopump_v4_baner_left h2 span {
  position: relative;
  min-height: 60px;
  min-width: 60px;
  margin-left: 20px;
}

.autopump_v4_baner_left h2 span .cat_foot_print {
}

.autopump_v4_baner_left h2 span .cat_walk_round_shape {
  position: absolute;
  min-height: 60px;
  min-width: 60px;
  left: -19px;
  top: 16px;
}
.autopump_v4_baner_right {
  margin-top: 0px;
  position: relative;
  min-height: 330px;
  margin-top: 40px;
}

.autopump_v4_baner_right span.star1 {
  position: absolute;
  right: 20%;
  top: 70px;
}
.autopump_v4_baner_right span.star2 {
  position: absolute;
  left: -20px;
  bottom: 90px;
}
.autopump_v4_baner_right span.star3 {
  position: absolute;
  left: 20px;
  bottom: -55px;
}

.autopump_v4_baner_right_content {
  position: relative;
  width: 100%;
}
.autopump_v4_baner_right_overlay {
  position: absolute;
  z-index: 0;
  left: -165px;
  top: -77px;
}
.v4_baner_img_card {
  background: rgba(83, 83, 83, 0.5);
  backdrop-filter: blur(20px);
  height: 350px;
  width: 280px;
  padding: 20px;
}
.v4_baner_img_card1 {
  position: absolute;
  top: 70px;
  left: 30px;
  transform: rotate(-15deg);
  z-index: 1;
}
.v4_baner_img_card2 {
  position: absolute;
  top: 120px;
  right: 100px;
  z-index: 1;
}
.v4roadmap_img_card_right {
  margin-left: auto;
}
.v4_baner_img {
  max-width: 240px;
  height: 246px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.v4_baner_img_shapes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.v4_baner_img_shapes_left .shape_1 {
  background: rgba(255, 255, 255, 0.1);
  height: 15px;
  width: 115px;
}
.v4_baner_img_shapes_left .shape_2 {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  height: 8px;
  width: 75px;
}
.v4_baner_img_shapes_right .shape_3 {
  background: rgba(255, 255, 255, 0.1);
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.autopump_v4owl_carousel_sect {
  background: #00ffa3;
  color: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.autopump_v4owl_carousel_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.autopump_v4owl_carousel_content ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 0px !important;
  animation: 20s linear 0s infinite normal none running loop-animate;
}
.autopump_v4owl_carousel_content ul li {
  margin-left: 20px;
  min-width: 810px;
  font-family: "Bakbak One";
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: white !important;
}
.autopump_v4owl_carousel_content ul li span {
  margin-right: 19px;
}
.triader_benefits_sect {
  background: rgba(25, 35, 40, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding-top: 80px;
  padding-bottom: 80px;
}
.triader_benefits_card {
  margin-top: 63px;
}
.cat_imgs_sect {
  padding: 0px 30px;
}
.p-10 {
  padding: 10px !important;
}

.v4_team_sect {
  background: rgba(25, 35, 40, 0.5);
  backdrop-filter: blur(5px);
  padding: 113px 0px 87px 0px;
  margin-top: 140px;
}

.v4roadmap_img_card_left {
  transform: rotate(15deg);
}
.v4roadmap_img_card_right {
  transform: rotate(-15deg);
  text-align: right;
}
.v4roadmap_text h4 {
  color: #ffffff;
}
.v4roadmap_text_right {
  padding-left: 74px;
}
.v4roadmap_text_left {
  text-align: right;
  padding-right: 74px;
}
.icon_check:after {
  content: url(../images/icon/check-icon.svg);
}
.icon_munies:after {
  content: url(../images/icon/muines_icon.svg);
}

.v4roadmap_text_right ul li span {
  margin-right: 14px;
}
.v4roadmap_text_left ul li span {
  margin-left: 14px;
}
.v4roadmap_text ul li {
  margin-bottom: 15px;
}
.v4_roadmap_content {
  z-index: 1;
  position: relative;
  padding: 0px 84.5px;
}
.v4roadmap_text_left ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.v4roadmap_text_right ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.autopump_v4_footer_sect {
  background: url(../images/bg/v4_footer_bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.autopump_v4_main_footer {
  min-height: auto;
}
.v4_footer_bottom {
  position: relative;
}

.v4_about_us_sect {
  background: url(../images/bg/v4_about_us_bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 630px;
}
.v4_about_us_left {
  padding-top: 46px;
  padding-right: 56px;
}
.v4_about_us_left ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.v4_about_us_left ul li {
  width: 50%;
  margin: 88px 0px;
  color: #ffffff;
}
.v4_about_us_right {
  padding-top: 134px;
}
.v4about_us_text {
  max-width: 570px;
  width: 100%;
  margin-top: 46px;
}
.autopump_v4_roadmap_mass_gard {
  position: absolute;
  top: 28%;
  text-align: center;
  width: 100%;
}
.v4_roadmap_sect {
  padding-bottom: 0px;
}

.cat_imgs_sect {
}
.cat_imgs_sect ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cat_imgs_sect {
  position: relative;
  z-index: 1;
}
.cat_imgs_sect ul li {
  margin: 0px 10px;
  height: 290px;
  width: 290px;
}
.cat_imgs_row1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.cat_imgs_row1 ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden;
  animation: 20s linear 0s infinite normal none running loop-animate;
  margin-bottom: 20px;
}

@keyframes loop-animate {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-100%);
  }
}

.cat_imgs_row2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
.cat_imgs_row2 ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-shrink: 0;
  overflow: hidden;
  animation: 13s linear 0s infinite normal none running loop-animate-reverse;
}

@keyframes loop-animate-reverse {
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(0%);
  }
}

/*--------------------------
Mint Sect
--------------------------*/
.autopump_mint_header {
  position: fixed;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0px 40px;
  overflow: hidden;
}
.autopump_mint_header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.logo {
  width: 80px;
}

.autopump_mint_header_left .logo a {
  position: relative;
  z-index: 1200;
}
.autopump_mint_header_right {
  margin-left: auto;
}
.mint_menu_btn {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  height: 50px;
  width: 50px;
  z-index: 1200;
}

.mint_menu_btn .menux_icon {
  display: none;
}
.mint_menu_btn.active {
  background: rgba(255, 255, 255, 0.2);
}
.mint_menu_btn.active .menux_icon {
  display: block;
}
.mint_menu_btn.active .menu_icon {
  display: none;
}
.autopump_mint_body {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.autopump_mint1_body {
  padding: 188px 0px;
}
.autopump_mint2_body {
  padding: 137px 0px;
}
.autopump_mint1_bg {
  background: url(../images/bg/mint-1_bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 0;
}
.autopump_mint2_bg {
  background: url(../images/bg/mint-2_bg.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 0;
}
.autopump_mint_bg_overlay {
  background: linear-gradient(180deg, rgba(4, 12, 18, 0.8) 0%, #040c12 100%);
  height: 100%;
  width: 100%;
}
.firefly_bg {
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
}
.v4firefly_bg {
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.autopump_mint_body_content {
  position: relative;
  z-index: 3;
}
.autopump_mint1_body_left_content {
  min-width: 372px;
  width: 100%;
  margin-left: auto;
}
.autopump_mint1_body_left p {
  font-size: 14px;
  font-weight: 500;
  margin-top: 23px;
}
.autopump_mint1_body_left p a {
  font-weight: 600;
  color: #ffffff;
}
.autopump_mint1_body_right {
  color: #ffffff;
  /* padding-top: 52px; */
  position: relative;
}
.mint1_mash_gard {
  position: absolute;
  z-index: -1;
  left: -50px;
}
.autopump_mint1_body_right h1 {
  font-size: 60px;
}
.autopump_mint1_body_right_timer {
  max-width: 364px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 53px;
  color: #ffffff;
}
.autopump_mint1_body_right_timer h5 {
  font-size: 18px;
}
.autopump_mint1_body_right_timer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.autopump_mint1_body_right_timer ul li {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
}
.autopump_mint1_body_right_timer ul li span {
  font-size: 24px;
}

.autopump_mint1_body_right_timer ul li:before {
  position: absolute;
  right: -25px;
  top: -2px;
  content: ":";
  color: rgba(255, 255, 255, 0.5);
}
.autopump_mint1_body_right_timer ul li.seconds:before {
  display: none;
}

.autopump_mint1_body_right_btns {
  max-width: 430px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 82px;
}

.mint_menu_section {
  background: #051117;
  display: none;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 1000;
}
.mint_menu_section.active {
  animation: mint_menu 0.4s;
  display: block;
}
@keyframes mint_menu {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.mint_menu_content {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1300;
  overflow: hidden;
}
.mint_menu_mashgard {
  position: absolute;
  right: -250px;
  top: -350px;
  z-index: 1301;
}
.mint_menu_left_bg {
  background: rgba(255, 255, 255, 0.08);
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: 1000;
}
.mint_menu_overlay {
  background: url(../images/bg/coming-soon-grain-texture.png);
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: cover;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1001;
}

.mintSlides {
  display: none;
}

.mint-slideshow-container {
  width: 250px;
  position: relative;
  margin: auto;
}

.mint-item_img {
  display: flex;
}

.mint2_text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  height: auto;
  min-height: 96px;
  color: #ffffff;
}
.mint2_counter {
  text-align: center;
  align-items: center;
}
.mint2_whitlst {
  align-items: flex-end;
}

.mint_item_card {
  background: rgba(36, 44, 52, 0.7);
  backdrop-filter: blur(10px);
  padding: 30px;
  position: relative;
}
.mint_item_card_text {
  color: #ffffff;
}
.mint_item_card_tag {
  position: absolute;
  top: 0px;
  left: 0px;
}

/*-----------------------------
Comming Soon
-----------------------------*/
.autopump_mint_coming_soon_content {
  position: relative;
  z-index: 2;
}
.autopump_coming_soon_bg {
  background: url(../images/bg/pixel_bg.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 0;
}
.autopump_coming_soon_bg_overlay {
  background: url(../images/bg/coming-soon-grain-texture.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  opacity: 0.7;
  position: relative;
  z-index: 1;
}
.autopump_coming_soon_body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.autopump_cooming_soon_mashgard {
  position: absolute;
  z-index: 0;
  max-height: 100%;
  overflow: hidden;
}
.autopump_cooming_soon_body_content {
  position: relative;
  max-width: 568px;
  margin: auto;
  text-align: center;
}
.autopump_cooming_soon_body_content h2 {
  font-size: 40px;
  color: #ffffff;
}
.coming_soon_timer .timer ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coming_soon_timer .timer ul li {
  max-width: 100px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.coming_soon_timer .timer ul li span {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  bottom: -30px;
}
.autopump_cooming_soon_body_content h5 {
  max-width: 270px;
  margin: auto;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  line-height: 167%;
  margin-top: 77px;
}

.comming_son_notify_me_form {
  max-width: 470px;
  width: 100%;
  margin: auto;
  margin-top: 24px;
}
.comming_son_notify_me_form form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.comming_son_notify_me_form form input {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  height: 60px;
  border-right: none;
  width: 320px;
  padding: 0px 21px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  color: #ffffff;
}

.comming_son_notify_me_form form input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.comming_son_notify_me_form form button {
  background: #00ffa3;
  height: 60px;
  width: 150px;

  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #111111;
}
.comming_son_notify_me_form form button span {
  margin-left: 14px;
}

.autopump_comming_soon_btns {
  max-width: 430px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 50px;
}

/*--------------------
mint-menu
---------------------*/
.mint_menu_inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1302;
  overflow-y: scroll;
  height: 100vh;
}
.mint_menu_inner::-webkit-scrollbar {
  display: none;
}
.mint_menu_left {
  padding-top: 30px;
  max-width: 50%;
  width: 100%;
  margin-left: auto;
  padding-right: 100px;
}
.mint_menu_left h2 {
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
}
.mint_menu_right {
  max-width: 50%;
  width: 100%;
  margin-right: auto;
  padding-left: 64px;
  padding-top: 142px;
}

.mint_home_menu {
  margin-top: 59px;
}
.mint_home_menu h5 {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.mint_home_menu ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mint_home_menu ul li {
  margin-bottom: 25px;
  width: 48%;
  overflow: hidden;
}

.mint_right_menu_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.mint_right_menu_list h6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
}
.mint_right_menu_list h6 span {
  margin-bottom: 3px;
}
.mint_right_menu_list1 {
  max-width: 100%;
  width: 100%;
  margin-right: auto;
  margin-bottom: 38px;
}
.mint_right_menu_list2 {
  max-width: 100%;
  width: 100%;
}
.mint_right_menu_list1_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 40%;
}
.mint_right_menu_list h5 {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.mint_right_menu_list ul li a {
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.8);
}
.mint_right_menu_list ul li a:hover {
  color: #ffffff;
}

.mint_right_menu_list2 .mint_social_links ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding-top: 15px;
}
.mint_right_menu_list2 .mint_social_links ul li {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin-bottom: 20px;
}
ight_menu_list2 .mint_social_links ul li.mint_social_links6 {
  margin-right: 0px;
}
.mint_right_menu_list2 .mint_social_links ul li a {
  color: #ffffff;
  font-size: 18px;
}

/*-------------------
Index 5
-------------------*/
.autopump_h5_body {
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.autopump-h5-slider .slideshow-container {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: auto;
}

.autopump-h5-slider img.v5-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
}

.autopump-h5-slider .home5-slide-btns {
  min-width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  z-index: 100;
}
.autopump-h5-slider .home5-slide-btns button {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  width: 16.67%;
  transition: background-color 0.6s ease;
  min-height: 60px;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.autopump-h5-slider .home5-slide-btns button:nth-last-child(1) {
  border: none;
}
.autopump-h5-slider .home5-slide-btns button.active,
.autopump-h5-slider .home5-slide-btns button:hover {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
}

.autopump-h5-slider .v5-owl-carousel {
  position: fixed;
  z-index: 10;
  bottom: 60px;
  background: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.autopump_v4owl_carousel_content.v5owl_carousel_content ul li {
  color: #ffffff;
}

/*-----------
V5 - Home
-----------*/

.v5-home-content {
  position: relative;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background: url(../images/bg/v5-baner1.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 170px;
}
.v5-home-container {
  position: relative;
}
.v5-home-container span.v5-home-thunder-icon {
  position: absolute;
  left: -90px;
  top: -50px;
  animation: icon-thunder 5s linear infinite;
}
@keyframes icon-thunder {
  0%,
  100% {
    top: -60px;
  }

  50% {
    top: -40px;
  }
}
.v5-home-container span.v5-home-star-icon {
  position: absolute;
  right: 100px;
  top: -120px;
  animation: icon-star 5s linear infinite;
}
@keyframes icon-star {
  0%,
  100% {
    top: -130px;
  }

  50% {
    top: -110px;
  }
}
.v5-home-container span.v5-home-coin-icon {
  position: absolute;
  right: -150px;
  bottom: 250px;
}

.v5-home-content-left {
  font-family: "Bakbak One";
  font-weight: 400;
}
.v5-home-content-left h2 {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 32px;
}
.v5-home-content-left h3 {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 58px;
}
.v5-home-content-left .v5-home-baner-buttons {
}
.v5-home-content-left ul {
  margin-top: 37px;
}
.v5-home-content-left ul li {
  font-size: 18px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.8);
}
.v5-home-content-left ul li span {
  color: #ffe600;
}

/*------------
v5-home-right-change-img
-------------*/

.v5-home-content-right {
  padding-top: 63px;
  text-align: right;
}
.myautoSlides {
  display: none;
}

.autoslideshow-container {
  max-width: 382px;
  position: relative;
  margin-left: auto;
}

/*-----------
V5 - About
-----------*/
.v5-about-content {
  position: relative;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background: url(../images/bg/v5-baner2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 220px;
}
.v5-about-left {
  padding-right: 50px;
}
.v5-about-left-text {
  max-width: 560px;
  width: 100%;
  margin-top: 46px;
}
.v5-about-left-text p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 20px;
}

.v5-about-right {
  padding-top: 70px;
  padding-left: 50px;
}
.v5-about-right ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.v5-about-right ul li {
  width: 50%;
  margin-bottom: 185px;
}
.v5-about-right ul li h5 {
  font-size: 40px;
  line-height: 36px;
  text-transform: uppercase;
}
.v5-about-right ul li h6 {
  font-size: 18px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.8);
}

/*-------------
V5 Roadmap
-------------*/
.v5-roadmap-content {
  position: relative;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 10;
  background: url(../images/bg/v5-baner3.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 200px;
}
.v5-roadmap-header-bg {
  background: rgba(217, 217, 217, 0.08);
  height: 50px;
  width: 100%;
  position: relative;
  margin-top: 25px;
}
.v5-roadmap-content-container {
  position: relative;
}
.v5-roadmap-iner {
  margin-top: -50px;
  min-width: 100%;
  overflow: hidden;
}
.v5-roadmap-slick-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.v5-roadmap-text {
  width: 100%;
  padding-right: 30px;
}
.v5-roadmap-text h3 {
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 28px;
}
.v5-roadmap-text ul {
}
.v5-roadmap-text ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 158%;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.v5-roadmap-text ul li span {
  margin-right: 10px;
}
.slick-initialized .slick-slide {
  width: 400px !important;
}
.roadmap-slider .owl-stage {
  margin-top: 8px;
}

.roadmap-slider .owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 2px;
}

.roadmap-slider .owl-nav .owl-prev,
.roadmap-slider .owl-nav .owl-next {
  width: 50px;
  height: 50px;
  background: rgba(217, 217, 217, 0.15) !important;
}

/*-------------
V5 Team
-------------*/

.v5-team-content {
  position: relative;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background: url(../images/bg/v5-baner4.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 140px;
}
.v5-team-inner-cards {
  padding-top: 58px;
}
.v5-team-inner-cards ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 510px;
}
.v5-team-inner-cards ul.team-row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 829px;
  width: 100%;
  margin: auto;
}
.v5-team-inner-cards ul li {
  max-width: 170px;
  width: 100%;
  margin: auto;
  margin-top: 0px;
  min-height: 170px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.v5-team-inner-cards ul li.t2,
.v5-team-inner-cards ul li.t5 {
  margin-top: 170px;
}
.v5-team-inner-cards ul li.t5,
.v5-team-inner-cards ul li.t7 {
}
.v5-team-inner-cards ul li span {
  position: absolute;
  bottom: 0px;
}
.v5-team-inner-cards ul li .team-hov-info {
  height: 100%;
  width: 100%;
  top: 0px;
  position: absolute;
  background: transparent;
  padding: 25px 23px;
  text-align: left;
  display: none;
}
.v5-team-inner-cards ul li:hover span {
  filter: blur(10px);
  opacity: 15%;
}
.v5-team-inner-cards ul li:hover .team-hov-info {
  animation-name: slide-left;
  animation-duration: 0.5s;
  display: block;
}

@keyframes slide-left {
  from {
    left: -100%;
  }

  to {
    left: 0%;
  }
}

.v5-team-inner-cards ul li .team-hov-info h5 {
  font-size: 18px;
  color: #ffffff;
  line-height: 25px;
  margin-bottom: 6px;
}
.v5-team-inner-cards ul li .team-hov-info p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 43px;
}
.v5-team-inner-cards ul li .team-hov-info .social-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 84px;
  width: 100%;
}
.v5-team-inner-cards ul li .team-hov-info .social-icons a {
  font-size: 18px;
  color: #ffffff;
}

/*-----------
V5 FAQ
------------*/
.v5-faq-content {
  position: relative;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background: url(../images/bg/v5-baner5.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 170px;
}
.v5-faq-inner {
  padding-top: 57px;
}

/*-----------
V5 Mint
------------*/
.v5-mint-content {
  position: relative;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background: url(../images/bg/v5-baner6.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 200px;
}

.v5-mint-left {
  padding-right: 40px;
}
.v5-mint-left-img {
  max-width: 370px;
  width: 100%;
  margin-left: auto;
  background: rgba(255, 255, 255, 0.1);
  padding: 60px 55px;
  text-align: center;
}
.myMintSlides {
  display: none;
}

.mintslideshow-container {
  max-width: 382px;
  width: 100%;
  position: relative;
  margin-left: auto;
}
.v5-mint-right {
  padding-left: 40px;
}
.v5-mint-right-inner {
  max-width: 370px;
  margin-right: auto;
}
.v5-mint-right-inner p a {
  font-weight: 600;
  color: #ffffff;
}
.v5-mint-right-inner p {
  margin-top: 23px;
}

/*----------------
FAQ Page
----------------*/
.faq-body-content-left-sect {
  padding-right: 20px;
}
.faq-body-content-left {
  width: 100%;
  padding: 18px 30px;
  min-height: 500px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  margin-bottom: 50px;
}
.faq-tab-btns {
}
.faq-tab-btns li {
  width: 100%;
  font-family: "Bakbak One";
  font-size: 24px;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.faq-tab-btns li.active,
.faq-tab-btns li:hover {
  color: #00ffa3;
}

/*---------
Contuct Us
---------*/
.contuctus-form-sect {
}
.contuctus-form-sect form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 570px;
  width: 100%;
}

.contuctus-body-section h4 {
  color: #00ffa3;
  margin-bottom: 13px;
}
.contuctus-body-section h2 {
  font-size: 48px;
  line-height: 67px;
  color: #ffffff;
  margin-bottom: 37px;
}

.contuctus-form-sect form label {
  font-family: "Bakbak One";
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  line-height: 45px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}
.contuctus-form-sect form input {
  border: 2px solid rgba(255, 255, 255, 0.1);
  height: 60px;
  width: 100%;
  background: transparent;
  outline: none;
  padding: 8px 21px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 22px;
}
.contuctus-form-sect form textarea {
  border: 2px solid rgba(255, 255, 255, 0.1);
  resize: none;
  height: 130px;
  width: 100%;
  background: transparent;
  outline: none;
  padding: 8px 21px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 40px;
}

.contactus-right-sect {
  padding-top: 40px;
  padding-left: 110px;
}
.contactus-address-info-sect h4 {
  color: #ffffff !important;
  margin-bottom: 25px;
}
.contactus-address-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.contactinfo-text {
  margin-left: 18px;
}
.contactinfo-text h5 {
  color: #ffffff;
  font-size: 18px;
  line-height: 158%;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.contactinfo-text p {
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
}

/*----------------
Roadmap
----------------*/
.roadmap-breadcrumbs-text {
}
.roadmap-breadcrumbs-text h2 {
  font-size: 48px;
  color: #ffffff;
}
.roadmap-content-row h2 {
  color: #ffffff;
  font-size: 48px;
  text-transform: uppercase;
}

.roadmap-content-card {
  background: rgba(255, 255, 255, 0.01);
  min-height: 340px;
  width: 100%;
  margin-top: 23px;
  position: relative;
  overflow: hidden;
  padding: 17px 19px;
}
.roadmap-content-bg-shape {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 30%;
  z-index: 0;
}
.roadmap-content-card.active .roadmap-content-bg-shape {
  opacity: 70%;
}
.roadmap-content-text {
  position: relative;
}
.roadmap-content-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}
.roadmap-content-icon-rotate {
}
.roadmap-content-icon-rotate {
  -webkit-animation: spin-style 2s linear infinite;
}
@keyframes spin-style {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
.roadmap-content-text h3 {
  color: #ffffff;
}
.roadmap-content-card.active .roadmap-content-text h3 {
  color: #00ffa3;
}
.roadmap-content-text ul {
  margin-top: 17px;
}
.roadmap-content-text ul li {
  padding-left: 19px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 17px;
  position: relative;
}
.roadmap-content-text ul li:before {
  content: "";
  height: 10px;
  width: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 9px;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 8px;
}

.roadmap-content-text ul li.upcoming:before {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/*-------------------
About Us
--------------------*/

.about-story-left {
  max-width: 539px;
  margin-right: auto;
}
.about-story-left h2 {
  font-size: 48px;
  color: #ffffff;
  line-height: 67px;
  margin-bottom: 43px;
}
.about-story-left p {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}
.about-story-left button {
  margin-top: 45px;
}
.about-story-right {
}
.about-story-right ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 470px;
  width: 100%;
}
.about-story-right ul li {
  width: 47%;
  margin-bottom: 30px;
}
.aboutus-total-counter-sect {
  background: rgba(255, 255, 255, 0.02);
}

/*-------------------
Collections
--------------------*/

.collections-filter-checkbox-collaps .collections-body-left {
  max-width: 250px;
  width: 100%;
}
.collections-price-filter {
  position: relative;
}
.collections-price-filter:after {
  content: url(../images/icon/down-arrow.svg);
  position: absolute;
  right: 15px;
  top: 11px;
}
.collections-price-filter select {
  appearance: none;
  background: transparent;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding: 0px 15px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  height: 50px;
  width: 100%;
}
.collections-price-filter select option {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding: 0px 15px;
  background: #11181e;
}

.collections-filter-checkbox-collaps .collapsible {
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  padding: 14px 15px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  position: relative;
  margin-top: 20px;
}
.collections-filter-checkbox-collaps .collapsible button {
  border: none;
  outline: none;
  background: transparent;
  font-family: "Bakbak One";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  text-transform: uppercase;
}
.collections-filter-checkbox-collaps .collapsible:after {
  content: url(../images/icon/filter-plus.svg);
  position: absolute;
  right: 15px;
  top: 14px;
}
.collections-filter-checkbox-collaps .active:after {
  content: url(../images/icon/filter-munis.svg);
}
.collections-filter-checkbox-collaps .collapsible .content {
  display: none;
  background: transparent;
}
.collections-filter-checkbox-collaps .collapsible.active .content {
  display: block;
  padding-top: 15px;
}

.collections-filter-checkbox .checkboxContainer {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
}
.collections-filter-checkbox .checkboxContainer span {
  color: rgba(255, 255, 255, 0.8);
}
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.collections-filter-checkbox .checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 16px;
  width: 16px;
  background: transparent;
  border-radius: 3px;
  border: 2px solid rgba(255, 255, 255, 0.12);
}
.collections-filter-checkbox .checkboxContainer input:checked ~ .checkmark {
  background: #00ffa3;
  border: none;
}
.collections-filter-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.collections-filter-checkbox
  .checkboxContainer
  input:checked
  ~ .checkmark:after {
  display: block;
}
.collections-filter-checkbox .checkboxContainer .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #111111;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.collections-body-right {
  padding-left: 20px;
}
.collections-product-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.08);
  max-width: 270px;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;
}
.collections-product-info {
  padding: 15px 24px;
}

.collections-product-info h6 {
  margin-bottom: 11px;
  font-weight: 600;
  color: #ffffff;
  font-family: "Inter";
}
.collections-product-info p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

/* //////  MY STYLES //////// */

.presale-status-indicator-icon-wrapper img {
  margin-bottom: 5px;
}

.flex-gap-mint-section {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px !important;
}

.red {
  color: #e84142;
  font-weight: bold;
  margin-left: 2px;
}

.blue {
  color: rgb(0, 110, 255);
  font-weight: bold;
  margin-left: 2px;
}

.green {
  color: #00ffa3;
}

.road-map-ul {
  padding-left: 0px;
}

.road-map-ul li {
  margin-block: 15px;
  position: relative;
}

.road-map-ul li h5 {
  display: inline;
}

.road-map-ul li.completed i {
  margin-right: 9px;
  position: absolute;
  z-index: 1;
  left: -25px;
  top: 4px;
  color: #00ffa3;
}

.road-map-ul li.not-completed i {
  margin-right: 9px;
  position: absolute;
  z-index: 1;
  left: -25px;
  top: 4px;
  color: #777;
}

.team_member_img img {
  width: 150px;
}

.discord-link-icon,
.twitter-link-icon,
.github-link-icon,
.telegram-link-icon {
  transition: background-color 0.3s ease;
  background-color: transparent;
  /* Add more common styles here if needed */
}

.discord-link-icon:hover {
  background-color: #7289da; /* Blurple */
}

.twitter-link-icon:hover {
  background-color: black; /* Light Blue */
}

.github-link-icon:hover {
  background-color: #333; /* Black */
}

.telegram-link-icon:hover {
  background-color: #2ca5e0; /* Light Blue */
}

.z-index {
  z-index: 9;
}

.connect-address {
  font-weight: 400;
  color: #00ffa3;
  margin-left: 10px;
  padding-inline: 15px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 0px;
  justify-content: center;
}

.tokenomics {
  position: relative;
  background: url(../images/bg/h1_about_us_bg.png);
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.tokenomics-image {
  position: absolute;
  left: -30px;
  bottom: 0;
  width: 180px;
}

tspan {
  text-transform: uppercase;
  font-weight: 600;
}

.connect-btn-wrapper {
  margin-right: 20px;
}
.feature-card-heading {
  font-size: 1.5rem;
  display: flex;
  gap: 0px;
  align-items: flex-end;
}

.feature-card-heading img {
  width: 60px;
  margin-right: 20px;
}

@media (min-width: 991px) {
  .menu_bar .fa-solid.fa-bars {
    display: none;
  }
}

@media (min-width: 678px) {
  .menu_bar {
    margin-left: 10px;
  }
}

.fee-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.circle-container .circle {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Light grey background */
  animation: rotateCircle 20s linear infinite;
}

.circle-container .circle-number {
  font-size: 24px;
  font-weight: bold;
}

/* Circle Animation */
@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Adding some flair to the circles */
.fees-circle,
.fees-circle2 {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(145deg, #009688, #005662); /* A cool gradient */
  animation: pulse 5s infinite ease-in-out;
}

.fees-circle2 {
  width: 140px;
  height: 140px;
  background: linear-gradient(145deg, #ff5722, #c41c00); /* Another gradient */
  z-index: -1;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.95);
  }
}

.fees {
  background: rgba(25, 35, 40, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding-block: 80px;
}

.okvir {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 48vh;
  top: 6vh;
  justify-content: center;
  gap: 30px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  z-index: -100;
  margin-top: 50px;
}

.okvir .card {
  background: transparent;
  position: relative;
  display: flex;
  width: 200px;
  height: 250px;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border-radius: 44px;
  transition: 0.5s;
  /* -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s; */
}

.okvir .card:hover {
  transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  box-shadow: 0 15px 35px rgba(0, 255, 17, 0.5);
}

.okvir .box {
  position: absolute;
  z-index: 99;
}

.okvir .percent {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: inset 0 0 50px #000;
  background: #222;
  z-index: 1000;
}

.okvir .percent .number {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.okvir .percent .number h2 {
  color: #777;
  font-weight: 700;
  font-size: 40px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.okvir .card:hover .percent .number h2 {
  color: #fff;
  font-size: 50px;
}

.okvir .percent .number h2 span {
  font-size: 24px;
  color: #777;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.okvir .card:hover .percent .number h2 span {
  color: #fff;
}

.okvir .text {
  position: relative;
  margin-top: 20px;
  color: #3f51b5;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.okvir .card:hover .text {
  color: #fff;
}

.okvir svg {
  position: relative;
  width: 150px;
  height: 150px;
  z-index: 1000;
  /* transform: rotate(-82deg);
  -webkit-transform: rotate(-82deg);
  -moz-transform: rotate(-82deg);
  -ms-transform: rotate(-82deg);
  -o-transform: rotate(-82deg); */
}

.okvir svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  /* stroke: #191919;
  stroke-width: 10;
  stroke-linecap: round; */
  /* transform: translate(5px, 5px);
  -webkit-transform: translate(5px, 5px);
  -moz-transform: translate(5px, 5px);
  -ms-transform: translate(5px, 5px);
  -o-transform: translate(5px, 5px); */
  z-index: 1000;
}
.okvir svg circle:nth-child(2) {
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

.okvir .text.AutoPump,
.okvir .card .number.AutoPump h2,
.okvir .card .number.AutoPump h2 span {
  color: #00ff43;
}

.okvir .text.Burn,
.okvir .card .number.Burn h2,
.okvir .card .number.Burn h2 span {
  color: red;
}

.okvir .text.Liquidity,
.okvir .card .number.Liquidity h2,
.okvir .card .number.Liquidity h2 span {
  color: rgba(0, 81, 255, 0.918);
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 440;
  }
}

.main-banner-logos .swapping-logos img {
  width: 160px;
}

.swapping-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 30px;
  gap: 20px;
}

.sushiswap-logo,
.uniswap-logo {
  width: 160px;
}

.listed-on-text {
  width: fit-content;
  margin-top: 100px;
  margin-inline: auto;
}

.main-banner-image {
  width: 300px;
  margin-top: 50px;
}

/* Not Found Page */

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: "Arial", sans-serif;
}

.not-found-title {
  font-size: 8rem;
  margin: 0;
  background: url("../../assets/images/bg/banner_bg_3.webp") no-repeat;
  -webkit-background-clip: text;
  color: transparent;
  background-size: cover;
}

.not-found-subtitle {
  font-size: 3rem;
  color: #999;
  margin-block: 30px;
}

.not-found-message {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

.not-found-container .home-button {
  background-color: #00ffa3;
  font-weight: 600;
  color: black;
  border: 2px solid transparent;
  padding: 18px 25px;
  transition: all 0.3s;
}

.not-found-container .home-button:hover {
  border: 2px solid var(--sky-light-blue);
  background-color: transparent;
  border: 2px solid #00ffa3;
  color: #00ffa3;
}

@media (max-width: 768px) {
  .not-found-title {
    font-size: 10vw;
  }

  .not-found-subtitle {
    font-size: 5vw;
  }

  .not-found-message {
    font-size: 4vw;
  }
}

.autopump_mint_body_content .row {
  justify-content: center;
}

/* progress bar */

.progress-container {
  margin: 0px 0px;
  width: 100%;
}

.progress-container span {
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}

.progress-container .progress2 {
  width: 100%;
  padding: 6px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-container .progress-bar2 {
  height: 18px;
  border-radius: 30px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-container .progress-moved .progress-bar2 {
  background: #00ffa3;
}

.white {
  color: white;
}

/* Mint Section */

.mint-section {
  position: relative;
}

.mint-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
}

.mint-section .go-back-home {
  color: #00fca1;
  font-weight: 800;
  text-transform: uppercase;
}

/* header mint section */

.mint-section .mobile_menu_btns button.connect_btn {
  margin-top: 0px !important;
}

/* scrolling bar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #00fca1;
  border-radius: 3px;
}

/* input of tyope number remove the up and down arrows */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* lootie-banner image */

.lottie-banner-image {
  width: 400px;
  height: 400px;
}

.team-description-text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  letter-spacing: 1px;
}

/* statics */

.statics {
  padding-left: 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statics li {
  display: flex;
  gap: 10px;
  color: rgba(255, 255, 255, 0.8);
}

/* Animated Button */

.btn-animate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  overflow: hidden;
  height: 3rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 2.5px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
  transition: all 0.3;
}

.btn-animate.uniswap {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      rgb(252, 114, 255) 10%,
      #fff 45%,
      rgb(252, 114, 255) 67%,
      rgb(252, 48, 255) 87%
    );
}

.btn-animate.sushiswap {
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(137.48deg, #f953a1 10%, #549cd8 45%, #9f7abe 67%, #fff 87%);
}

.btn-animate #container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
}

.btn-animate strong {
  z-index: 2;
  font-family: "Poppins" sans-serif;
  font-size: 16px;
  letter-spacing: 3px;
  color: white;
  text-shadow: 0 0 4px rgb(0, 0, 0);
  transition: all 0.3;
}

.btn-animate #glow {
  position: absolute;
  display: flex;
  width: 12rem;
}

.btn-animate .circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.btn-animate .circle:nth-of-type(1) {
  background: rgba(0, 0, 1860 0.936);
}

.btn-animate .circle:nth-of-type(2) {
  background: rgba(0, 0, 1860 0.936);
}

.btn-animate img {
  z-index: 9;
  margin-right: 10px;
}

.btn-animate:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.btn-animate:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn-animate:active .circle {
  background: #fe53bb;
}

.btn-animate:hover strong {
  animation: gradient_301 1s ease infinite;
  border: double 3.5px transparent;
  color: #fe53bb;
}

@media (min-width: 500px) {
  .btn-animate #stars {
    position: relative;
    background: transparent;
    width: 200rem;
    height: 200rem;
  }
  .btn-animate #stars::after {
    content: "";
    position: absolute;
    top: -10rem;
    left: -100rem;
    width: 100%;
    height: 100%;
    animation: animStarRotate 90s linear infinite;
  }

  .btn-animate #stars::after {
    background-image: radial-gradient(#ffffff 1px, transparent 1%);
    background-size: 50px 50px;
  }

  .btn-animate #stars::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 170%;
    height: 500%;
    animation: animStar 60s linear infinite;
  }

  .btn-animate #stars::before {
    background-image: radial-gradient(#ffffff 1px, transparent 1%);
    background-size: 50px 50px;
    opacity: 0.5;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* Fire */

@keyframes scaleUpDown {
  0%,
  100% {
    transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    transform: scaleY(1.1);
  }
  75% {
    transform: scaleY(0.95);
  }
  80% {
    transform: scaleX(0.95);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: skewX(0) scale(1);
  }
  50% {
    transform: skewX(5deg) scale(0.9);
  }
}

@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    transform: scale(0.5);
  }
}

@keyframes glow {
  0%,
  100% {
    background-color: #c9df7a;
  }
  50% {
    background-color: #3ef1a6;
  }
}

.fire {
  margin: 100px auto 0px;
  position: relative;
  width: 200px;
  height: 200px;
  background-color: transparent;
}

.fire-center {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: scaleUpDown 3s ease-out infinite;
  animation-fill-mode: both;
}

.fire-center .main-fire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    farthest-corner at 10px 0,
    #3ef1a6 0%,
    #3ef1a6 95%
  );
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  filter: drop-shadow(0 0 10px #ccff0f);
}

.fire-center .particle-fire {
  position: absolute;
  top: 60%;
  left: 45%;
  width: 10px;
  height: 10px;
  background-color: #ccff0f;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px #3ef1a6);
  animation: particleUp 2s ease-out infinite;
  animation-fill-mode: both;
}

.fire-right {
  height: 100%;
  width: 100%;
  position: absolute;
  animation: shake 2s ease-out infinite;
  animation-fill-mode: both;
}

.fire-right .main-fire {
  position: absolute;
  top: 15%;
  right: -25%;
  width: 80%;
  height: 80%;
  background-color: #3ef1a6;
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  filter: drop-shadow(0 0 10px #ccff0f);
}

.fire-right .particle-fire {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #3ef1a6;
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 50%;
  filter: drop-shadow(0 0 10px #ccff0f);
  animation: particleUp 2s ease-out infinite;
  animation-fill-mode: both;
}

.fire-left {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: shake 3s ease-out infinite;
  animation-fill-mode: both;
}

.fire-left .main-fire {
  position: absolute;
  top: 15%;
  left: -20%;
  width: 80%;
  height: 80%;
  background-color: #3ef1a6;
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  filter: drop-shadow(0 0 10px #ccff0f);
}

.fire-left .particle-fire {
  position: absolute;
  top: 10%;
  left: 20%;
  width: 10%;
  height: 10%;
  background-color: #3ef1a6;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px #ccff0f);
  animation: particleUp 3s infinite ease-out;
  animation-fill-mode: both;
}

.fire-bottom .main-fire {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 75%;
  height: 75%;
  background-color: #3ef1a6;
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 100% 40%;
  filter: blur(10px);
  animation: glow 2s ease-out infinite;
  animation-fill-mode: both;
}

.token-burned-number {
  position: absolute;
  font-family: "Bakbak One";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 600;
  color: black;
}

.token-burned-number span {
  color: black !important;
}

/* base-can */

.base-can-link {
  text-transform: uppercase;
  font-weight: 600;
  width: fit-content;
  margin: 40px auto;
  display: block;
  font-size: 18px;
}

.base-can-link:hover {
  color: #00ffa3;
}

/* Audit By */
.audit-by-container a {
  width: 100px;
}

/* loader */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #00ffa3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* swaping buttons */

.swaping-buttons {
  display: flex;
  gap: 10px;
  margin-block: 10px;
}

@media (max-width: 680px) {
  .swaping-buttons {
    flex-direction: column;
    margin-inline: auto;
    width: fit-content;
  }
}

/* tokens section */
.tokens {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.tokens-header {
  display: flex;
  gap: 20px;
  margin-inline: auto;
  width: fit-content;
  align-items: center;
}

.tokens-header .autopump_title_section{
  margin-left: 50px;
}

@media (max-width:500px) {
  .tokens-header{
    flex-direction: column;
    gap: 0;
    margin-bottom: 20px;
  }
  .tokens-header .autopump_title_section{
    margin-left: 0px;
  }
}

.tokens .swaps-logos {
  display: flex;
  gap: 50px;
  align-items: center;
  flex-direction: row-reverse;
}

.contract-address {
  display: flex;
}

.contract-address a {
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
  font-family: "Bakbak One";
}

.contract-address a:hover {
  color: white;
}

@media (max-width: 500px) {
  .tokens .swaps-logos {
    flex-direction: column-reverse;
  }
  .contract-address {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.tokens .swaps-logos .base-image {
  width: 120px;
  height: 30px;
  margin-top: 10px;
}

.tokens img {
  width: 150px;
}

.tokens a {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.3s;
}

.tokens a i {
  margin-top: 17px;
  color: pink;
  transition: all 0.3s;
}

.tokens a:hover i {
  color: #ff007a;
}

/* burn */

.burn-section {
  min-height: 300px;
  padding-block: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
