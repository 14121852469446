/* styles.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is on top of other content */
  }
  
  .modal-content {
    background-color: #0e2920;
    padding: 20px;
    width: 550px;
    margin-inline: auto;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content .modal-title {
    color: #333; /* Dark text color for the title */
  }
  
  .modal-content .modal {
    background: white;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    color: #2c2c2e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  
  .modal-content .close-button {
    cursor: pointer;
      font-size: 13px;
      padding: 0px 5px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #999;
      font-weight: 600;
      position: absolute;
      top: 4px;
      right: 9px;
  }
  
  .modal-content .modal-body h2 {
    color: var(--orange-primary);
    font-weight: 800;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  
  .modal-content .modal-body p {
    font-weight: 600;
    line-height: 20px;
    color: #999;
  }
  
  .modal-content .modal-icon {
    width: fit-content;
    margin-inline: auto;
  }
  
  .modal-content .modal-icon img {
    width: 200px;
    min-height: 110px;
  }
  
  .modal-content .modal-support {
    margin-top: 20px;
    font-size: 0.9rem;
  }
  
  .modal-content .modal-support a {
    color: #4caf50;
  }
  
  .modal-content .modal-footer {
    margin-top: 20px;
    font-size: 0.9rem;
    color: var(--orange-primary) !important;
    font-weight: 800 !important;
  }
  
  @media (max-width: 992px) {
    .modal-content {
      width: 550px;
    }
  }
  
  @media (max-width: 768px) {
    .modal-content {
      width: 80%;
    }
  }
  
  @media (max-width: 350px) {
    .modal-content {
      width: 90%;
      padding: 0;
    }
  }
  